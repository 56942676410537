import { IonSpinner } from "@ionic/react";
import styled from "styled-components";

const Container = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	ion-spinner {
		width: 3em;
		height: 3em;
	}
`;

const LoadingScreen = () => (
	<Container>
		<IonSpinner />
	</Container>
);

export default LoadingScreen;
