import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "../../../common/services/firebase";

export const vote = async (sessionId: string, filename: string, isDownvote?: boolean): Promise<void> => {
	const user = auth.currentUser;

	if (user) {
		await setDoc(doc(db, "users", user.uid, "sessions", sessionId, "votes", filename), {
			vote: isDownvote ? -1 : 1,
		});
	}

	return;
};
