import { IonIcon, IonItem, IonLabel, IonNote, IonToggle, useIonPicker, useIonToast } from "@ionic/react";
import { caretDown } from "ionicons/icons";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { INote, IVocalRange } from "../../../common/interfaces";
import { midiToNote } from "../../../common/utils";
import { NOTE_OPTIONS } from "../../voice-type-selection/constants/defaultVocalRange";
import { useExerciseContext } from "../constates/exercise";
import { useExerciseSettingsContext } from "../constates/exerciseSettings";

const generateTempoOptions = (defaultTempo: number) => {
	const start = Math.ceil(defaultTempo / 2);
	const range = defaultTempo * 2;
	return Array.from(Array(range - start + 1).keys())
		.map((_, idx) => (start + idx).toString())
		.reverse();
};

const ExerciseSettings = forwardRef((props, ref) => {
	useImperativeHandle(ref, () => ({
		save() {
			if (draftTempo !== tempo) {
				setTempo(draftTempo);
			}
			if (draftRange !== range) {
				setRange(draftRange);
			}
			if (draftVocalPlaybackDisabled !== vocalPlaybackDisabled) {
				setVocalPlaybackDisabled(draftVocalPlaybackDisabled);
			}
		},
	}));

	const [tempoOptions, setTempoOptions] = useState<string[]>([]);

	const [present] = useIonPicker();
	const [presentToast] = useIonToast();

	const { tempo, setTempo, range, setRange, vocalPlaybackDisabled, setVocalPlaybackDisabled } = useExerciseSettingsContext();
	const { exercise } = useExerciseContext();

	const [draftTempo, setDraftTempo] = useState<number>(tempo);
	const [draftRange, setDraftRange] = useState<IVocalRange>(range);
	const [draftVocalPlaybackDisabled, setDraftVocalPlaybackDisabled] = useState<boolean>(vocalPlaybackDisabled);

	useEffect(() => {
		if (exercise) setTempoOptions(generateTempoOptions(exercise.baseTempo));
	}, [exercise]);

	return (
		<>
			<IonItem
				button
				detail={false}
				onClick={() =>
					present({
						buttons: [
							{
								text: "Cancel",
								handler: () => {},
							},
							{
								text: "Confirm",
								handler: (selected) => {
									setDraftTempo(Number(selected.tempo.value));
								},
							},
						],
						columns: [
							{
								name: "tempo",
								options: tempoOptions.map((option) => ({ text: option, value: option })),
								selectedIndex: tempoOptions.findIndex((option) => tempo.toString() === option),
							},
						],
					})
				}
			>
				<IonLabel>Tempo (BPM)</IonLabel>
				<IonNote color="primary">{draftTempo}</IonNote>
				<IonIcon icon={caretDown} slot="end" size="small" />
			</IonItem>

			<IonItem
				button
				detail={false}
				onClick={() =>
					present({
						buttons: [
							{
								text: "Cancel",
								handler: () => {},
							},
							{
								text: "Confirm",
								handler: (selected) => {
									const lowNote = selected.lowNote as INote;
									const highNote = selected.highNote as INote;

									if (selected.lowNote.value >= selected.highNote.value) {
										presentToast({
											color: "warning",
											message: "The low note must be greater than the high note!",
											duration: 3000,
										});
									} else {
										setDraftRange({
											lowNote: lowNote.text,
											highNote: highNote.text,
											lowNoteMidi: lowNote.value,
											highNoteMidi: highNote.value,
										});
									}
								},
							},
						],
						columns: [
							{
								name: "lowNote",
								options: NOTE_OPTIONS.map((option) => ({ text: midiToNote(option), value: option })),
								selectedIndex: NOTE_OPTIONS.findIndex((option) => range.lowNoteMidi === option),
							},
							{
								name: "hyphen",
								options: [{ text: "-", value: "-" }],
								selectedIndex: 0,
								columnWidth: "8px",
							},
							{
								name: "highNote",
								options: NOTE_OPTIONS.map((option) => ({ text: midiToNote(option), value: option })),
								selectedIndex: NOTE_OPTIONS.findIndex((option) => range.highNoteMidi === option),
							},
						],
					})
				}
			>
				<IonLabel>Range</IonLabel>
				<IonNote color="primary">{draftRange.lowNote + " - " + draftRange.highNote}</IonNote>
				<IonIcon icon={caretDown} slot="end" size="small" />
			</IonItem>

			<IonItem>
				<IonToggle aria-label="disable-vocal-playback" checked={draftVocalPlaybackDisabled} onIonChange={(e: any) => setDraftVocalPlaybackDisabled(e.detail.checked)}>
					Mute melody?
				</IonToggle>
			</IonItem>
		</>
	);
});

export default ExerciseSettings;
