import { initializeApp } from "firebase/app";
import { browserLocalPersistence, getAuth, setPersistence } from "firebase/auth";
import { CACHE_SIZE_UNLIMITED, initializeFirestore, persistentLocalCache, persistentSingleTabManager } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyBYEwWA-TBDwlqV5YmMIFNyfID-sJgk-00",
	authDomain: "cantare-f77a3.firebaseapp.com",
	projectId: "cantare-f77a3",
	storageBucket: "cantare-f77a3.appspot.com",
	messagingSenderId: "627835317842",
	appId: "1:627835317842:web:caf70367620fea12a7398b",
	measurementId: "G-PG7T86HS5F",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = initializeFirestore(app, {
	localCache: persistentLocalCache(
		/*settings*/ {
			tabManager: persistentSingleTabManager({
				forceOwnership: true,
			}),
			cacheSizeBytes: CACHE_SIZE_UNLIMITED,
		}
	),
});

// export const analytics = getAnalytics(app);
export const firebaseStorage = getStorage();

export const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence).catch((error) => {
	console.log("Err", error);
	// Handle Errors here.
	// const errorCode = error.code;
	// const errorMessage = error.message;
});
