import { doc, onSnapshot, Unsubscribe } from "firebase/firestore";
import { atom } from "jotai";
import { db } from "../../common/services/firebase";
import userAtom from "../user/state";
import { IVoiceType } from "./constants/voiceTypes";

const INIT = Symbol(); // or anything that doesn't conflict with data.
const DESTROY = Symbol();

// playlistsAtom.onMount = (setAtom) => {
// 	const q = query(collection(db, "playlists"));
// 	onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
// 		const newExercises = snapshot.docs.map((doc) => ({ ...(doc.data() as IExercise), id: doc.id }));
// 		setAtom(newExercises);
// 	});
// };

const defaultAtom = atom<IVoiceType | undefined | null>(null);
const subscriptionAtom = atom<Unsubscribe | undefined>(undefined);

const voiceTypeAtom = atom(
	(get) => get(defaultAtom),
	(get, set, arg) => {
		if (arg === INIT) {
			const userId = get(userAtom)?.id;
			const docRef = doc(db, `users/${userId}`);

			onSnapshot(docRef, (doc) => {
				if (doc.exists()) {
					set(defaultAtom, doc.data().voiceType);
				} else {
					set(defaultAtom, undefined);
				}
			});
		} else if (arg === DESTROY) {
			const unsubscribe = get(subscriptionAtom);
			if (unsubscribe) {
				unsubscribe();
			}
		} else {
			set(defaultAtom, arg as IVoiceType);
		}
	}
);

voiceTypeAtom.onMount = (dispatch) => {
	dispatch(INIT);
};

export default voiceTypeAtom;
