import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonTitle, IonToolbar } from "@ionic/react";
import { checkmark, close } from "ionicons/icons";
import { useRef } from "react";
import { useExerciseSettingsContext } from "../constates/exerciseSettings";
import ExerciseSettings from "./ExerciseSettings";

interface Props {
	onDismiss: () => void;
}

const ExerciseSettingsScreen = ({ onDismiss }: Props) => {
	const exerciseSettingsRef = useRef<any>();
	const { reset } = useExerciseSettingsContext();

	return (
		<>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonButton fill="clear" onClick={onDismiss}>
							<IonIcon icon={close} />
						</IonButton>
					</IonButtons>
					<IonTitle>Exercise Settings</IonTitle>
					<IonButtons slot="end">
						<IonButton
							fill="clear"
							onClick={() => {
								onDismiss();
								setTimeout(() => {
									if (exerciseSettingsRef.current) {
										exerciseSettingsRef.current.save();
									}
								}, 0);
							}}
						>
							<IonIcon icon={checkmark} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<ExerciseSettings ref={exerciseSettingsRef} />
			</IonContent>
			<IonFooter>
				<IonButton
					fill="clear"
					color="danger"
					expand="block"
					onClick={() => {
						onDismiss();
						setTimeout(() => {
							reset();
						}, 0);
					}}
				>
					Reset Settings
				</IonButton>
			</IonFooter>
		</>
	);
};

export default ExerciseSettingsScreen;
