import { IonButton, IonButtons, IonContent, IonHeader, IonList, IonPage, IonTitle, IonToolbar, ItemReorderEventDetail, useIonAlert } from "@ionic/react";
import { useAtom, useAtomValue } from "jotai";
import { useState } from "react";
import LoadingScreen from "../../common/components/LoadingScreen";
import userAtom from "../user/state";
import { createPlaylist, deletePlaylist, renamePlaylist, reorderPlaylists } from "./actions";
import Playlists from "./components/Playlists";
import playlistsAtom from "./state";

const PlaylistsPage = () => {
	const [present] = useIonAlert();
	const user = useAtomValue(userAtom);
	const [playlists, setPlaylists] = useAtom(playlistsAtom);
	const [isReordering, setIsReordering] = useState(false);

	const showCreatePlaylistPrompt = (userId: string) => {
		present({
			cssClass: "my-css",
			inputs: [
				{
					type: "text",
					name: "name",
				},
			],
			header: "Create a new routine",
			message: "What do you want to name it?",
			buttons: [
				"Cancel",
				{
					text: "Confirm",
					handler: ({ name }) => {
						if (name?.length) createPlaylist(userId, name, playlists.length);
					},
				},
			],
			onDidDismiss: (e) => {},
		});
	};

	const onReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
		// Update the "index" variable of each playlist to reflect it's new index
		// in the list
		const updatedPlaylists = [...playlists];
		updatedPlaylists[event.detail.from].index = event.detail.to;
		updatedPlaylists[event.detail.to].index = event.detail.from;

		// Update the playlists in the state
		setPlaylists(updatedPlaylists);

		// Finish the reorder and position the item in the DOM based on
		// where the gesture ended. This method can also be called directly
		// by the reorder group
		event.detail.complete();
	};

	const saveReorder = () => {
		if (!user) return;
		const reorderedPlaylistIds = [...playlists].sort((a, b) => a.index - b.index).map((playlist) => playlist.id);

		if (reorderedPlaylistIds.join() !== playlists.map((playlist) => playlist.id).join()) {
			reorderPlaylists(user.id, reorderedPlaylistIds);
		}
		setIsReordering(false);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Routines</IonTitle>
					{user && (
						<>
							<IonButtons slot="start">{!isReordering && playlists.length && <IonButton onClick={() => setIsReordering(true)}>Reorder</IonButton>}</IonButtons>
							<IonButtons slot="end">
								{isReordering ? <IonButton onClick={saveReorder}>Done</IonButton> : <IonButton onClick={() => showCreatePlaylistPrompt(user.id)}>Create</IonButton>}
							</IonButtons>
						</>
					)}
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				{user && playlists ? (
					<IonList style={{ marginTop: "0.3rem" }}>
						<Playlists
							playlists={playlists}
							createPlaylist={() => showCreatePlaylistPrompt(user.id)}
							deletePlaylist={(playlistId: string) => {
								if (user.id) {
									deletePlaylist(user.id, playlistId);
								}
							}}
							renamePlaylist={(playlistId: string, name: string) => {
								if (user.id) {
									renamePlaylist(user.id, playlistId, name);
								}
							}}
							isReordering={isReordering}
							onReorder={onReorder}
						/>
					</IonList>
				) : (
					<LoadingScreen />
				)}
			</IonContent>
		</IonPage>
	);
};

export default PlaylistsPage;
