import { useIonToast } from "@ionic/react";
import React, { ReactNode, useRef } from "react";
import styled from "styled-components";

const StyledInput = styled.input`
	opacity: 0;
	position: absolute;
	width: 120%;
	height: 120%;
	cursor: pointer;
`;

interface FileUploadButtonProps {
	onAttach: (files: File[]) => void;
	children: ReactNode;
	className?: string;
	accept?: string;
	sizeLimit?: number;
}

const FileUploadButton = ({
	onAttach,
	children,
	accept = "*",
	sizeLimit = 100, // MB
}: FileUploadButtonProps) => {
	const inputRef = useRef<any>();
	const [presentToast] = useIonToast();

	return (
		<>
			{children}
			<StyledInput
				ref={inputRef}
				type="file"
				accept={accept}
				// multiple
				onChange={(e) => {
					if (e.target.files) {
						let files = Array.from(e.target.files);
						if (sizeLimit) {
							files = files.filter((file) => file.size / 1024 / 1024 <= sizeLimit);
						}
						if (e.target.files.length > files.length) {
							presentToast({
								color: "danger",
								message: "Some files exceeded the size limit (100MB) and were skipped.",
								duration: 2000,
								position: "top",
							});
						}
						onAttach(files);
						inputRef.current.value = "";
					}
				}}
			/>
		</>
	);
};

export default FileUploadButton;
