import { IonItem } from "@ionic/react";
import styled from "styled-components";

const StyledIonItem = styled(IonItem)`
	padding-top: 0.3rem;
	padding-bottom: 0.3rem;
	border-bottom: 1px solid #e0e0e0;
`;

export default StyledIonItem;
