import { AudioContext } from "standardized-audio-context";
import { ActionType } from "../actions";

import Microphone from "./Microphone";

export enum Status {
	INITIALIZING,
	READY,
	STARTED,
	STOPPED,
}
class Recorder {
	private audioContext: AudioContext;
	public microphone: Microphone | undefined;

	public status: Status = Status.INITIALIZING;

	private blobs: Blob[] = [];
	private onComplete: (blobs: Blob[]) => void;
	private blobLimit: number = 0;
	private recorderWorker: Worker | undefined;

	constructor(blobLimit: number, onComplete: (blobs: Blob[]) => void, sampleRate?: number) {
		const latencyHint = "interactive";
		this.audioContext = sampleRate ? new AudioContext({ sampleRate, latencyHint }) : new AudioContext({ latencyHint });

		this.blobLimit = blobLimit;
		this.onComplete = onComplete;
	}

	public async setupMicrophone() {
		if (this.microphone) return;

		this.recorderWorker = new Worker(new URL("../../workers/encoder.worker.ts", import.meta.url));
		this.recorderWorker.postMessage({
			action: ActionType.INIT,
			payload: {
				sampleRate: this.audioContext.sampleRate,
				channels: 1,
				bitRate: 128,
			},
		});

		this.recorderWorker.onmessage = ({ data: { blob } }) => {
			this.blobs.push(blob);

			if (this.blobs.length === this.blobLimit) {
				this.onComplete(this.blobs.slice(0, this.blobLimit));
			}
		};

		try {
			const microphone = new Microphone(this.audioContext);
			await microphone.setup();

			await microphone.addNode(
				"recorder",
				(_event: any) => {
					this.recorderWorker?.postMessage({
						action: ActionType.PROCESS,
						payload: {
							floatSamples: _event.buffer,
						},
					});
				},
				{
					numberOfInputs: 1,
					numberOfOutputs: 1,
					outputChannelCount: [1],
				}
			);

			this.microphone = microphone;
		} catch (e) {
			console.error(e);
		}
	}

	public clearAudioBlobs() {
		this.blobs = [];
	}

	public async start() {
		await this.setupMicrophone();
		await this.microphone?.start();
		this.status = Status.STARTED;
	}

	public async stop(willClear?: boolean, isEnd?: boolean) {
		if (willClear) {
			this.clearAudioBlobs();
		} else {
			this.recorderWorker?.postMessage({
				action: ActionType.END,
			});
		}
		await this.microphone?.stop();

		this.status = Status.STOPPED;
	}

	public async pause() {
		this.microphone?.stop();
	}

	public async resume() {
		this.microphone?.start();
	}
}

export default Recorder;
