import { deleteDoc, doc } from "firebase/firestore";
import { auth, db } from "../../../common/services/firebase";

export const unvote = async (sessionId: string, filename: string): Promise<void> => {
	const user = auth.currentUser;

	if (user) {
		await deleteDoc(doc(db, "users", user.uid, "sessions", sessionId, "votes", filename));
	}

	return;
};
