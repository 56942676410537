import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonNote, IonPage, IonSearchbar, IonTitle, IonToolbar, useIonPopover } from "@ionic/react";
import {
	arrowDownOutline,
	arrowUpOutline,
	bookmarkOutline,
	checkmarkCircle,
	chevronDown,
	chevronForward,
	cloudUploadOutline,
	downloadOutline,
	ellipsisVertical,
	play,
	radioButtonOff,
} from "ionicons/icons";
import { useAtom, useAtomValue } from "jotai";
import { useState } from "react";
import { useHistory } from "react-router";
import FileUploadButton from "../../../common/components/FileUploadButton";
import ItemOptions from "../../../common/components/ItemOptions";
import StyledIonItem from "../../../common/components/StyledIonItem";
import { IExercise } from "../../exercise/interfaces/exercise";
import AddToPlaylistsModal from "../../playlists/AddToPlaylists";
import { todaySessionsAtom } from "../../sessions/state";
import { userIdAtom } from "../../user/state";
import { deleteExercise } from "../actions/deleteExercise";
import { renameExercise } from "../actions/renameExercise";
import exercisesAtom from "../atoms/exercisesAtom";
import useExerciseDownload from "../hooks/useExerciseDownload";
import useExerciseUpload from "../hooks/useExerciseUpload";

const ExercisesPage = () => {
	const userId = useAtomValue(userIdAtom);
	const [exercises] = useAtom(exercisesAtom);
	const todaySessions = useAtomValue(todaySessionsAtom);
	const history = useHistory();
	const [searchText, setSearchText] = useState("");

	const { downloadedExerciseIds, percentDownloadedByExercise, download } = useExerciseDownload();
	const { setFilesToBeUploaded, exerciseUploads, percentUploadedByExercise } = useExerciseUpload();
	const [presentPopover, dismiss] = useIonPopover(ItemOptions, {
		onHide: () => dismiss(),
		onDelete: () => {
			if (selectedExercise) {
				deleteExercise(selectedExercise);
			}
		},
		onRename: (name: string) => {
			if (selectedExercise) {
				renameExercise(selectedExercise.id, name);
			}
		},
	});

	const [selectedExercise, setSelectedExercise] = useState<IExercise>();

	const filteredExercises = exercises.filter((exercise) => exercise.name.toLowerCase().includes(searchText.toLowerCase())).filter((exercise) => !(exercise.id in percentUploadedByExercise));

	const [showAddToPlaylistsModal, setShowAddToPlaylistsModal] = useState(false);
	const [selectedPlaylists, setSelectedPlaylists] = useState(new Set<string>());

	const handlePlaylistToggle = (playlistId: string) => {
		const newSelectedPlaylists = new Set(selectedPlaylists);
		if (newSelectedPlaylists.has(playlistId)) {
			newSelectedPlaylists.delete(playlistId);
		} else {
			newSelectedPlaylists.add(playlistId);
		}
		setSelectedPlaylists(newSelectedPlaylists);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Exercises</IonTitle>
					<IonButtons slot="end">
						<IonButton>
							<FileUploadButton onAttach={setFilesToBeUploaded} accept=".xml">
								<IonIcon slot="icon-only" icon={cloudUploadOutline} />
							</FileUploadButton>
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<IonToolbar>
					<IonSearchbar animated={true} value={searchText} onIonInput={(e) => setSearchText(e.detail.value!)}></IonSearchbar>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<IonList style={{ marginTop: "0.3rem" }}>
					{exerciseUploads.map((exercise) => (
						<StyledIonItem lines="none" detail={false} onClick={async (e) => {}} key={exercise.id}>
							<IonLabel>
								<h2>{exercise.name}</h2>
							</IonLabel>
							{exercise.id in percentUploadedByExercise && (
								<IonNote slot="end">
									<IonIcon icon={arrowUpOutline} />
									{percentUploadedByExercise[exercise.id]}%
								</IonNote>
							)}
						</StyledIonItem>
					))}
					{filteredExercises.length > 0 ? (
						filteredExercises.map((exercise) => (
							<div key={exercise.id}>
								<StyledIonItem
									lines="none"
									detail={false}
									onClick={async (e) => {
										if (!(exercise.id in percentDownloadedByExercise) && !downloadedExerciseIds.contains(exercise.id)) {
											await download(exercise);
										}
										if (!exercise.phonemePatterns?.length) {
											history.push(`/exercise/${exercise.id}`);
										} else {
											setSelectedExercise(selectedExercise?.id !== exercise.id ? exercise : undefined);
										}
									}}
								>
									<IonLabel>
										<h2>{exercise.name}</h2>
									</IonLabel>
									<IonIcon
										icon={bookmarkOutline}
										slot="start"
										onClick={(e) => {
											// Open modal page showing all playlists that can be added to
											e.preventDefault();
											e.stopPropagation();
											setSelectedExercise(exercise);
											setShowAddToPlaylistsModal(true);
										}}
									/>
									{!downloadedExerciseIds.contains(exercise?.id) &&
										!(exercise.id in percentDownloadedByExercise) &&
										(userId === exercise.createdBy ? (
											<IonIcon
												icon={ellipsisVertical}
												slot="end"
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													setSelectedExercise(exercise);
													presentPopover({
														event: e.nativeEvent,
													});
												}}
											/>
										) : (
											<IonIcon
												icon={downloadOutline}
												slot="end"
												onClick={async (e) => {
													e.stopPropagation();
													download(exercise);
												}}
											/>
										))}
									{!downloadedExerciseIds.contains(exercise?.id) && exercise.id in percentDownloadedByExercise && (
										<IonNote slot="end">
											<IonIcon icon={arrowDownOutline} /> {percentDownloadedByExercise[exercise.id]}%
										</IonNote>
									)}
									{downloadedExerciseIds.contains(exercise.id) && (
										<>
											{userId === exercise.createdBy && (
												<IonIcon
													icon={ellipsisVertical}
													slot="end"
													onClick={(e) => {
														e.preventDefault();
														e.stopPropagation();
														setSelectedExercise(exercise);
														presentPopover({
															event: e.nativeEvent,
														});
													}}
												/>
											)}
											{exercise && exercise.phonemePatterns?.length ? (
												<>{selectedExercise?.id === exercise.id ? <IonIcon icon={chevronDown} slot="start" /> : <IonIcon icon={chevronForward} slot="start" />}</>
											) : (
												<IonIcon icon={play} slot="end" />
											)}
										</>
									)}
								</StyledIonItem>
								{exercise &&
									exercise.phonemePatterns?.length &&
									selectedExercise?.id === exercise.id &&
									exercise.phonemePatterns.map((pattern) => (
										<StyledIonItem
											lines="none"
											detail={false}
											style={{ paddingLeft: "1.6rem" }}
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												history.push(`/exercise/${exercise.id}/${pattern}`);
											}}
											key={pattern}
										>
											{todaySessions.find((session) => session.exerciseId === exercise.id && (session.phonemePattern ? session.phonemePattern === pattern : true)) ? (
												<IonIcon icon={checkmarkCircle} slot="start" />
											) : (
												<IonIcon icon={radioButtonOff} slot="start" />
											)}
											<IonLabel>
												<h2>{pattern}</h2>
											</IonLabel>
											<IonIcon icon={play} slot="end" />
										</StyledIonItem>
									))}
							</div>
						))
					) : (
						<IonItem lines="none">
							<IonLabel className="ion-text-center">No results found</IonLabel>
						</IonItem>
					)}
				</IonList>
			</IonContent>
			{selectedExercise && (
				<AddToPlaylistsModal
					exerciseId={selectedExercise.id}
					isOpen={showAddToPlaylistsModal}
					selectedPlaylists={selectedPlaylists}
					setSelectedPlaylists={setSelectedPlaylists}
					onDismiss={() => setShowAddToPlaylistsModal(false)}
					onPlaylistToggle={handlePlaylistToggle}
				/>
			)}
		</IonPage>
	);
};

export default ExercisesPage;
