import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, useIonModal } from "@ionic/react";
import { bookmarksOutline, list, musicalNotes, person } from "ionicons/icons";
import { useAtomValue } from "jotai";
import React, { useEffect } from "react";
import { Redirect, Route } from "react-router";
import ExercisesPage from "../../features/exercises/pages/ExercisesPage";
import PlaylistsPage from "../../features/playlists/PlaylistsPage";
import SessionsPage from "../../features/sessions/SessionsPage";
import ProfilePage from "../../features/user/pages/ProfilePage";
import VoiceTypeSelectionPage from "../../features/voice-type-selection/pages/VoiceTypeSelectionPage";
import voiceTypeAtom from "../../features/voice-type-selection/state";

export interface ITab {
	key: string;
	value: string;
	icon: string;
	component: React.FC;
}

export const tabs: ITab[] = [
	{
		key: "exercises",
		value: "Exercises",
		icon: musicalNotes,
		component: ExercisesPage,
	},
	{
		key: "routines",
		value: "Routines",
		icon: bookmarksOutline,
		component: PlaylistsPage,
	},
	{
		key: "sessions",
		value: "Sessions",
		icon: list,
		component: SessionsPage,
	},
	{
		key: "profile",
		value: "Profile",
		icon: person,
		component: ProfilePage,
	},
];

const HomePage = () => {
	const [presentModal, dismissModal] = useIonModal(VoiceTypeSelectionPage, {
		onDismiss: () => {
			dismissModal();
		},
	});

	const voiceType = useAtomValue(voiceTypeAtom);

	useEffect(() => {
		if (voiceType === undefined) {
			presentModal();
		}
	}, [presentModal, voiceType]);

	return (
		<IonTabs>
			<IonRouterOutlet>
				{tabs.map((tab) => (
					<Route exact path={`/home/${tab.key}`} key={tab.key}>
						<tab.component />
					</Route>
				))}
				<Route exact path="/home">
					<Redirect to="/home/exercises" />
				</Route>
			</IonRouterOutlet>

			<IonTabBar slot="bottom">
				{tabs.map((tab) => (
					<IonTabButton tab={tab.value} href={`/home/${tab.key}`} key={tab.key}>
						<IonIcon icon={tab.icon} />
						<IonLabel>{tab.value}</IonLabel>
					</IonTabButton>
				))}
			</IonTabBar>
		</IonTabs>
	);
};

export default HomePage;
