import { addDoc, collection, deleteDoc, doc, getDocs, setDoc, updateDoc, writeBatch } from "firebase/firestore";
import { db } from "../../common/services/firebase";
import { IPlaylist } from "./interfaces";

export const createPlaylist = async (userId: string, name: string, index: number, exerciseId?: string): Promise<IPlaylist> => {
	const updatedAt = new Date().getTime();
	const playlist = {
		name,
		updatedAt,
		exerciseIds: exerciseId ? [exerciseId] : [],
		index,
	};
	const doc = await addDoc(collection(db, "users", userId, "playlists"), playlist);
	return {
		id: doc.id,
		...playlist,
	};
};

export const renamePlaylist = async (userId: string, playlistId: string, name: string): Promise<void> => {
	await setDoc(doc(db, "users", userId, "playlists", playlistId), {
		name,
		updatedAt: new Date().getTime(),
	});
};

export const updatePlaylist = async (userId: string, { id, name, exerciseIds }: IPlaylist): Promise<void> => {
	await updateDoc(doc(db, "users", userId, "playlists", id), {
		name,
		exerciseIds,
		updatedAt: new Date().getTime(),
	});
};

export const deletePlaylist = async (userId: string, playlistId: string): Promise<void> => {
	await deleteDoc(doc(db, "users", userId, "playlists", playlistId));
};

export const getPlaylistsFromDb = async (userId: string): Promise<IPlaylist[]> => {
	const playlistsCol = collection(db, `users/${userId}`, "playlists");
	const playlistsSnapshot = await getDocs(playlistsCol);
	return playlistsSnapshot.docs.map((doc) => ({
		...doc.data(),
	})) as IPlaylist[];
};

export const reorderPlaylists = async (userId: string, playlistIds: string[]): Promise<void> => {
	const batch = writeBatch(db);
	playlistIds.forEach((playlistId, index) => {
		batch.update(doc(db, "users", userId, "playlists", playlistId), {
			index,
		});
	});
	await batch.commit();
};

export const modifyExerciseInPlaylists = async (userId: string, playlists: IPlaylist[], playlistIdsToAddTo: Set<string>, exerciseId: string): Promise<void> => {
	const batch = writeBatch(db);
	playlists.forEach((playlist) => {
		if (playlistIdsToAddTo.has(playlist.id)) {
			if (!playlist.exerciseIds.includes(exerciseId))
				batch.update(doc(db, "users", userId, "playlists", playlist.id), {
					exerciseIds: [...playlist.exerciseIds, exerciseId],
				});
		} else {
			batch.update(doc(db, "users", userId, "playlists", playlist.id), {
				exerciseIds: playlist.exerciseIds.filter((id) => id !== exerciseId),
			});
		}
	});
	await batch.commit();
};

export const removeExerciseIdFromPlaylist = async (userId: string, playlist: IPlaylist, exerciseId: string): Promise<void> => {
	await updateDoc(doc(db, "users", userId, "playlists", playlist.id), {
		exerciseIds: playlist.exerciseIds.filter((id) => id !== exerciseId),
	});
};

export const updateExerciseIdsInPlaylist = async (userId: string, playlistId: string, exerciseIds: string[]): Promise<void> => {
	await updateDoc(doc(db, "users", userId, "playlists", playlistId), {
		exerciseIds,
		updatedAt: new Date().getTime(),
	});
};

export const getPlaylist = async (userId: string, playlistId: string) => {};
