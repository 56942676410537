import { IonItem, IonList, useIonAlert } from "@ionic/react";

interface Props {
	onHide: () => void;
	onRename: (name: string) => void;
	onDelete: () => void;
}

const ItemOptions = ({ onHide, onRename, onDelete }: Props) => {
	const [presentAlert] = useIonAlert();

	const showDeletePrompt = () => {
		onHide();
		presentAlert({
			header: "Are you sure you want to delete this item?",
			buttons: ["Cancel", { text: "Confirm", handler: onDelete }],
			onDidDismiss: (e) => {},
		});
	};

	const showRenamePrompt = () => {
		onHide();
		presentAlert({
			inputs: [
				{
					type: "text",
					name: "name",
				},
			],
			header: "What do you want to name it?",
			buttons: [
				"Cancel",
				{
					text: "Confirm",
					handler: ({ name }) => {
						if (name?.length) onRename(name);
					},
				},
			],
			onDidDismiss: (e) => {},
		});
	};

	return (
		<IonList lines="none">
			<IonItem
				button
				detail={false}
				onClick={() => {
					showRenamePrompt();
				}}
			>
				Rename
			</IonItem>
			<IonItem
				button
				detail={false}
				onClick={() => {
					showDeletePrompt();
				}}
			>
				Delete
			</IonItem>
			{/* <IonItem button>Share</IonItem> */}
		</IonList>
	);
};

export default ItemOptions;
