import { collection, onSnapshot, query } from "firebase/firestore";
import { atom } from "jotai";
import { db } from "../../../common/services/firebase";

export interface IPhoneme {
	value: string;
	index: number;
}

const phonemesAtom = atom<IPhoneme[]>([]);

phonemesAtom.onMount = (setAtom) => {
	const q = query(collection(db, `phonemes`));
	onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
		const results = snapshot.docs.map((doc) => doc.data() as IPhoneme).sort((a, b) => a.index - b.index);
		setAtom(results);
	});
};

export default phonemesAtom;
