import { IonButton, IonButtons, IonCheckbox, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemDivider, IonLabel, IonNote, IonTitle, IonToolbar, useIonPicker } from "@ionic/react";
import { caretDown, checkmark, close } from "ionicons/icons";
import { useAtomValue } from "jotai";
import { useRef, useState } from "react";
import styled from "styled-components";
import generateNumberArray from "../../../common/utils/generateNumberArray";
import removeEmptyFields from "../../../common/utils/removeEmptyFields";
import phonemesAtom, { IPhoneme } from "../atoms/phonemesAtom";
import { IExerciseUpload } from "../interfaces";

const TEMPO_OPTIONS = generateNumberArray(30, 180);

interface Props extends IExerciseUpload {
	onDismiss: () => void;
	onSave: (data: IExerciseUpload) => void;
}

const ErrorMessage = styled.div`
	display: flex;
	padding: 2rem;
	justify-content: center;
	color: red;
`;

const ExerciseUploadScreen = ({ onDismiss, onSave, name, baseTempo, createdAt, filename }: Props) => {
	const nameRef = useRef<HTMLIonInputElement>(null);
	const phonemes = useAtomValue<IPhoneme[]>(phonemesAtom);

	const [draftName, setDraftName] = useState(name || "");
	const [draftTempo, setDraftTempo] = useState(baseTempo || 90);
	const [draftPhonemes, setDraftPhonemes] = useState<string[]>([]);
	const [present] = useIonPicker();
	const [error, setError] = useState<string>("");

	return (
		<>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonButton fill="clear" onClick={onDismiss}>
							<IonIcon icon={close} />
						</IonButton>
					</IonButtons>
					<IonTitle>Upload Exercise</IonTitle>
					<IonButtons slot="end">
						<IonButton
							fill="clear"
							onClick={() => {
								if (!draftName) {
									nameRef.current?.setFocus();
									setError("Please provide a name for this exercise");
								} else {
									onSave(
										removeEmptyFields({
											name: draftName,
											baseTempo: draftTempo,
											filename,
											createdAt,
											phonemePatterns: draftPhonemes,
										}) as any
									);
								}
							}}
						>
							<IonIcon icon={checkmark} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonItem>
					<IonLabel position="fixed">Name</IonLabel>
					<IonInput
						ref={nameRef}
						value={draftName}
						aria-label="name"
						onIonInput={(e: any) => {
							setDraftName(e.detail.value!);
							setError("");
						}}
						style={{ textAlign: "right" }}
						autofocus={true}
						autocapitalize="words"
						required={true}
					></IonInput>
				</IonItem>
				<IonItem
					button
					detail={false}
					onClick={() =>
						present({
							buttons: [
								{
									text: "Cancel",
									handler: () => {},
								},
								{
									text: "Confirm",
									handler: (selected) => {
										setDraftTempo(Number(selected.tempo.value));
									},
								},
							],
							columns: [
								{
									name: "tempo",
									options: TEMPO_OPTIONS.map((option) => ({ text: option.toString(), value: option })),
									selectedIndex: TEMPO_OPTIONS.findIndex((option) => draftTempo.toString() === option.toString()),
								},
							],
						})
					}
				>
					<IonLabel>Default Tempo (BPM)</IonLabel>
					<IonNote color="primary">{draftTempo}</IonNote>
					<IonIcon icon={caretDown} slot="end" size="small" />
				</IonItem>
				<IonItemDivider>Phonemes</IonItemDivider>
				{/* <IonItem
					button
					detail={false}
					onClick={() =>
						present({
							buttons: [
								{
									text: "Cancel",
									handler: () => {},
								},
								{
									text: "Confirm",
									handler: (selected) => {
										setDraftPhoneme(selected.phoneme.value);
									},
								},
							],
							columns: [
								{
									name: "phoneme",
									options: phonemes.sort((a, b) => a.index - b.index).map(({ value }) => ({ text: value.length ? value : "(None)", value })),
									selectedIndex: phonemes.findIndex(({ value }) => draftPhoneme === value),
								},
							],
						})
					}
				>
					<IonLabel>Phoneme</IonLabel>
					<IonNote color="primary">{draftPhoneme?.length ? draftPhoneme : "(None)"}</IonNote>
					<IonIcon icon={caretDown} slot="end" size="small" />
				</IonItem> */}
				{phonemes.map(({ value, index }) => (
					<IonItem key={index}>
						<IonLabel>{value}</IonLabel>
						<IonCheckbox
							slot="end"
							value={value}
							checked={draftPhonemes.indexOf(value) > -1}
							onIonChange={(e) => {
								if (e.detail.checked) {
									setDraftPhonemes([...draftPhonemes, value]);
								} else {
									setDraftPhonemes(draftPhonemes.filter((phoneme) => phoneme !== value));
								}
							}}
						/>
					</IonItem>
				))}
				<ErrorMessage>{error}</ErrorMessage>
			</IonContent>
		</>
	);
};

export default ExerciseUploadScreen;
