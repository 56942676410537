import constate from "constate";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { IVocalRange } from "../../../common/interfaces";
import { noteToMidi } from "../../../common/utils";
import historyAtom from "../../exercises/atoms/historyAtom";
import vocalRangeAtom from "../../voice-type-selection/state";
import { IExercise } from "../interfaces/exercise";
import { getRange } from "./../../../common/utils/index";
import { DEFAULT_MAX_NOTE, DEFAULT_MIN_NOTE } from "./../../voice-type-selection/constants/defaultVocalRange";

interface Props {
	exercise: IExercise;
	xml: string;
}

const useExerciseSettings = ({ exercise, xml }: Props) => {
	const [tempo, setTempo] = useState(exercise.baseTempo || 72);

	const vocalRange = useAtomValue(vocalRangeAtom);
	const history = useAtomValue(historyAtom);

	const [exerciseRange] = useState(getRange(xml));

	const [range, setRange] = useState<IVocalRange>({
		lowNote: vocalRange ? vocalRange.lowNote : DEFAULT_MIN_NOTE,
		highNote: vocalRange ? vocalRange.highNote : DEFAULT_MAX_NOTE,
		lowNoteMidi: noteToMidi(vocalRange ? vocalRange.lowNote : DEFAULT_MIN_NOTE),
		highNoteMidi: noteToMidi(vocalRange ? vocalRange.highNote : DEFAULT_MAX_NOTE),
	});

	const [transposition, setTransposition] = useState(0);
	const [exerciseOffset, setExerciseOffset] = useState<number>();
	const [vocalPlaybackDisabled, setVocalPlaybackDisabled] = useState(false);

	useEffect(() => {
		if (range && exerciseRange) {
			setExerciseOffset(range.lowNoteMidi - exerciseRange.lowNoteMidi);
		} else {
			setExerciseOffset(0);
		}
	}, [exerciseRange, range]);

	useEffect(() => {
		if ((!history || !(exercise.id in history)) && vocalRange) {
			setRange(vocalRange);
		}
	}, [exercise.id, history, vocalRange]);

	const reset = () => {
		setTempo(exercise.baseTempo || 72);
		setRange({
			lowNote: vocalRange ? vocalRange.lowNote : DEFAULT_MIN_NOTE,
			highNote: vocalRange ? vocalRange.highNote : DEFAULT_MAX_NOTE,
			lowNoteMidi: noteToMidi(vocalRange ? vocalRange.lowNote : DEFAULT_MIN_NOTE),
			highNoteMidi: noteToMidi(vocalRange ? vocalRange.highNote : DEFAULT_MAX_NOTE),
		});

		if (exerciseOffset !== undefined) {
			setTransposition(exerciseOffset);
		}
	};

	useEffect(() => {
		if (history && exercise.id in history) {
			setTempo(history[exercise.id].tempo);
			setRange(history[exercise.id].range as IVocalRange);
			setVocalPlaybackDisabled(!!history[exercise.id].vocalPlaybackDisabled);
		}
	}, [exercise.id, history]);

	return {
		tempo,
		setTempo,
		range,
		setRange,
		transposition,
		setTransposition,
		vocalRange,
		exerciseRange,
		exerciseOffset,
		setExerciseOffset,
		vocalPlaybackDisabled,
		setVocalPlaybackDisabled,
		get exerciseSettings() {
			return {
				exerciseId: exercise.id,
				range,
				tempo,
				vocalPlaybackDisabled,
			};
		},
		reset,
	};
};

export const [ExerciseSettingsProvider, useExerciseSettingsContext] = constate(useExerciseSettings);
