import { IVocalRange } from "../interfaces";

const notesSharp = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"];
const notesFlat = ["C", "Db", "D", "Eb", "E", "F", "Gb", "G", "Ab", "A", "Bb", "B"];

interface Note {
	name: string;
	octave: number;
	accidental?: string;
}

export const midiToNote = (value: number, isKeyFlat?: boolean): string => {
	let name = notesSharp[value % 12];
	if (isKeyFlat) {
		name = notesFlat[value % 12];
	}
	const octave = Math.floor(value / 12) - 1;
	return name + octave;
};

export const noteToMidi = (note: string): number => {
	const octaveString = note.match(/(\d+)/);
	const octave = octaveString ? parseInt(octaveString[0]) : -1;
	const name = note.match(/[A-G](b|#)?/);
	const index = name ? notesSharp.indexOf(name[0]) || notesFlat.indexOf(name[0]) : -1;
	return index > -1 ? index + (octave + 1) * 12 : -1;
};

export const getMidiFromNote = (note: Note): number => {
	let midiNum = (Number(note.octave) + 1) * 12;
	const noteName = note.name.toLowerCase();
	if (noteName === "d") {
		midiNum += 2;
	} else if (noteName === "e") {
		midiNum += 4;
	} else if (noteName === "f") {
		midiNum += 5;
	} else if (noteName === "g") {
		midiNum += 7;
	} else if (noteName === "a") {
		midiNum += 9;
	} else if (noteName === "b") {
		midiNum += 11;
	}
	if (note.accidental === "b") {
		midiNum--;
	} else if (note.accidental === "#") {
		midiNum++;
	}
	return midiNum;
};

// Prevent default behavior

export const preventDefault = (event: Event) => {
	event.preventDefault();
	event.stopPropagation();
};

export const getParts = (xml: string): string[] => {
	const parser = new DOMParser();
	const document = parser.parseFromString(xml, "text/xml");
	const parts = document.getElementsByTagName("part-name");
	return Array.prototype.slice.call(parts).map((part) => part.textContent);
};

export const getRange = (xml: string, partNumber: number = 0): IVocalRange => {
	const parser = new DOMParser();
	const document = parser.parseFromString(xml, "text/xml");
	const parts = document.getElementsByTagName("part");
	const part = parts[partNumber];
	const pitchNodes = Array.prototype.slice.call(part.getElementsByTagName("pitch"));
	const pitches = pitchNodes.map((pitch) => {
		const name = pitch.getElementsByTagName("step")[0].textContent;
		const octave = Number(pitch.getElementsByTagName("octave")[0].textContent);
		const alter = pitch.getElementsByTagName("alter")[0]?.textContent;
		return noteToMidi(name + octave) + (alter ? Number(alter) : 0);
	});

	const min = pitches.reduce((min, pitch) => Math.min(min, pitch), Number.MAX_VALUE);
	const max = pitches.reduce((max, pitch) => Math.max(max, pitch), Number.MIN_VALUE);

	const isFlat = Number(document.getElementsByTagName("fifths")[0].textContent) < 0;

	return {
		lowNote: midiToNote(min, isFlat),
		highNote: midiToNote(max, isFlat),
		lowNoteMidi: min,
		highNoteMidi: max,
	};
};

export const updatePhonemePattern = (xml: string, phonemePattern: string): string => {
	const parser = new DOMParser();
	const s = new XMLSerializer();
	const document = parser.parseFromString(xml, "text/xml");

	let words = phonemePattern.split(" ").map((word) => word.split("-"));
	let wordIndex = 0;
	let syllableIndex = 0;
	const syllableCount = words.reduce((acc, cv) => acc + cv.length, 0);

	const parts = document.getElementsByTagName("part");
	const part_P1 = parts.item(0);
	if (part_P1) {
		// Remove all existing lyrics
		Array.prototype.slice.call(part_P1.getElementsByTagName("lyric")).forEach(function (item) {
			item.remove();
		});

		// Add new lyrics
		const pitchNodes = Array.prototype.slice.call(part_P1.getElementsByTagName("pitch"));

		if (syllableCount === 1) {
			words = pitchNodes.map(() => words[0]);
		} else if (words.length === 1 && words[0].length === 2 && words[0][1] === "") {
			words = [pitchNodes.map(() => words[0][0])];
		}

		pitchNodes.forEach((item) => {
			const noteNode = item.parentNode;

			const lyricNode = document.createElement("lyric");
			lyricNode.setAttribute("number", "1");
			lyricNode.setAttribute("default-x", "6.50");
			lyricNode.setAttribute("default-y", "-56.08");
			lyricNode.setAttribute("relative-y", "-30.00");

			const word = words[wordIndex];
			const syllable = word[syllableIndex];

			const syllabicNode = document.createElement("syllabic");
			if (word.length > 1) {
				if (syllableIndex === 0) {
					syllabicNode.textContent = "begin";
				} else if (syllableIndex === word.length - 1) {
					syllabicNode.textContent = "end";
				} else {
					syllabicNode.textContent = "middle";
				}
				lyricNode.appendChild(syllabicNode);
			}

			const textNode = document.createElement("text");
			textNode.textContent = syllable;

			lyricNode.appendChild(textNode);

			noteNode.appendChild(lyricNode);

			syllableIndex++;
			if (syllableIndex === word.length) {
				syllableIndex = 0;
				wordIndex++;
			}
			if (wordIndex === words.length) {
				wordIndex--;
			}
		});
	}

	return s.serializeToString(document);
};

export const addStaccato = (xml: string): string => {
	const parser = new DOMParser();
	const s = new XMLSerializer();
	const document = parser.parseFromString(xml, "text/xml");

	const parts = document.getElementsByTagName("part");
	const part_P1 = parts.item(0);

	if (part_P1) {
		Array.prototype.slice.call(part_P1.getElementsByTagName("pitch")).forEach(function (item) {
			const noteNode = item.parentNode;
			const hasNotations = noteNode.getElementsByTagName("notations").length > 0;
			if (!hasNotations) {
				const notationsNode = document.createElement("notations");
				noteNode.appendChild(notationsNode);
			}

			const hasArticulations = noteNode.getElementsByTagName("articulations").length > 0;
			if (!hasArticulations) {
				const articulationsNode = document.createElement("articulations");
				const notationsNode = noteNode.getElementsByTagName("notations")[0];
				notationsNode.appendChild(articulationsNode);
			}

			const articulationsNode = noteNode.getElementsByTagName("notations")[0].getElementsByTagName("articulations")[0];
			const staccatoNode = document.createElement("staccato");
			articulationsNode.appendChild(staccatoNode);
		});
	}

	return s.serializeToString(document);
};

export const removeStaccato = (xml: string): string => {
	const parser = new DOMParser();
	const s = new XMLSerializer();
	const document = parser.parseFromString(xml, "text/xml");

	const parts = document.getElementsByTagName("part");
	const part_P1 = parts.item(0);
	if (part_P1) {
		Array.prototype.slice.call(part_P1.getElementsByTagName("staccato")).forEach(function (item) {
			item.remove();
		});
	}

	return s.serializeToString(document);
};

interface IExerciseRange {
	start: string;
	end: string;
	distance: number;
}

export const getExerciseRange = (xml: string, isKeyFlat?: boolean): IExerciseRange | null => {
	const parser = new DOMParser();
	const document = parser.parseFromString(xml, "text/xml");

	const parts = document.getElementsByTagName("part");
	const part_P1 = parts.item(0);
	let minNote, maxNote;

	if (part_P1) {
		for (let i = 0; i < part_P1.getElementsByTagName("pitch").length; i++) {
			const pitch = part_P1.getElementsByTagName("pitch")[i];
			const noteNum = getMidiFromNote({
				name: pitch.getElementsByTagName("step")[0].textContent!,
				octave: Number(pitch.getElementsByTagName("octave")[0].textContent),
			});
			if (minNote === undefined || noteNum < minNote) {
				minNote = noteNum;
			}
			if (maxNote === undefined || noteNum > maxNote) {
				maxNote = noteNum;
			}
		}
	}

	return minNote && maxNote
		? ({
				start: midiToNote(minNote, isKeyFlat),
				end: midiToNote(maxNote, isKeyFlat),
				distance: !!maxNote && !!minNote ? maxNote - minNote : -1,
		  } as IExerciseRange)
		: null;
};

export const reverseExercise = (xml: string): string => {
	const parser = new DOMParser();
	const s = new XMLSerializer();
	const document = parser.parseFromString(xml, "text/xml");

	const parts = document.getElementsByTagName("part");
	const part_P1 = parts.item(0);

	if (part_P1) {
		const pitchNodes = Array.prototype.slice.call(part_P1.getElementsByTagName("pitch"));
		const pitches = pitchNodes
			.map((pitchNode) => {
				return {
					step: pitchNode.getElementsByTagName("step")[0].textContent!,
					octave: pitchNode.getElementsByTagName("octave")[0].textContent,
				};
			})
			.reverse();
		pitchNodes.forEach((pitchNode) => {
			const pitch = pitches.shift();
			if (pitch) {
				pitchNode.getElementsByTagName("step")[0].textContent = pitch.step;
				pitchNode.getElementsByTagName("octave")[0].textContent = pitch.octave;
			}
		});
	}

	return s.serializeToString(document);
};

export const invertExercise = (xml: string): string => {
	return xml;
};

export const getValueByTag = (xml: string, tag: string): string => {
	const parser = new DOMParser();
	const document = parser.parseFromString(xml, "text/xml");

	const elements = document.getElementsByTagName(tag);
	if (elements.length) {
		const value = elements.item(0)?.textContent;
		return value || "";
	}
	return "";
};

export const getElementCountByTag = (xml: string, tag: string): number => {
	const parser = new DOMParser();
	const document = parser.parseFromString(xml, "text/xml");

	const elements = document.getElementsByTagName(tag);
	return elements.length;
};
