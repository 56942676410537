import constate from "constate";
import { ClefEnum, ClefInstruction, OpenSheetMusicDisplay, TransposeCalculator } from "osmd-extended";
import { useCallback, useEffect, useState } from "react";
import { noteToMidi } from "./../../../common/utils/index";
import { useExerciseSettingsContext } from "./exerciseSettings";
import { useOSMDContext } from "./osmd";

const OSMD_OPTIONS = {
	autoResize: true,
	drawTitle: false,
	drawingParameters: "compact",
	drawPartNames: false,
	drawComposer: false,
	drawSubtitle: false,
	disableCursor: false,
	// cursorsOptions: [
	// 	{
	// 		type: CursorTypeAreaLeft,
	// 		color: "green",
	// 		alpha: 0.5,
	// 		follow: true,
	// 	},
	// ],
};

interface Props {
	xml: string;
	osmdRef: any;
	osmdPreviewRef: any;
	nextTextRef: any;
}

const useRender = ({ xml, osmdRef, osmdPreviewRef, nextTextRef }: Props) => {
	const { osmd, setOsmd, osmdPreview, setOsmdPreview, iteration, reverse, willBeLastRep, transposition } = useOSMDContext();
	const { tempo, exerciseRange, exerciseOffset } = useExerciseSettingsContext();
	const [clefType, setClefType] = useState<ClefEnum>(ClefEnum.G);

	const resize = useCallback(() => {
		if (osmd) {
			osmd.render();
		}
		if (osmdPreview) {
			osmdPreview.render();
		}
	}, [osmd, osmdPreview]);

	const renderSheetMusic = useCallback(
		async (xml: string) => {
			const osmd = new OpenSheetMusicDisplay(osmdRef.current, OSMD_OPTIONS) as any;
			await osmd.load(xml);
			osmd.zoom = 0.8;
			osmd.sheet.Instruments[1].Visible = !!!osmdPreviewRef.current;
			osmd.TransposeCalculator = new TransposeCalculator();
			setOsmd(osmd);

			if (osmdPreviewRef.current) {
				const osmdPreview = new OpenSheetMusicDisplay(osmdPreviewRef.current, { ...OSMD_OPTIONS, disableCursor: true }) as any;
				await osmdPreview.load(xml);

				osmdPreview.zoom = 0.8;
				osmdPreview.sheet.Instruments[1].Visible = false;
				osmdPreview.TransposeCalculator = new TransposeCalculator();
				setOsmdPreview(osmdPreview);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[osmdPreviewRef, osmdRef]
	);

	const processAnimations = useCallback(
		() => {
			const animationRate = 1000 * (60 / tempo);
			osmdRef.current.animate([{ opacity: 1 }, { opacity: 0 }], {
				duration: 1,
				easing: "ease-in-out",
				fill: "forwards",
			});
			osmdRef.current.style.opacity = "0";
			const { top: topCurrent } = osmdRef.current.getBoundingClientRect();

			if (osmdPreviewRef.current) {
				const { top: topPreview } = osmdPreviewRef.current.getBoundingClientRect();

				osmdPreviewRef.current.animate([{ transform: "translateY(0px)" }, { transform: `translateY(-${topPreview - topCurrent}px)` }], {
					duration: animationRate,
					easing: "ease-out",
				});
				osmdPreviewRef.current.animate([{ opacity: 0.5 }, { opacity: 1 }], animationRate);
				nextTextRef.current.animate([{ opacity: 0.8 }, { opacity: 0 }], {
					duration: animationRate / 2,
					fill: "forwards",
				});
			}

			setTimeout(() => {
				osmdRef.current.animate([{ opacity: 0 }, { opacity: 1 }], {
					duration: 1,
					easing: "ease-in-out",
					fill: "forwards",
				});
			}, animationRate - 20);

			if (osmd && osmdPreview) {
				setTimeout(() => {
					if (osmdPreviewRef?.current && nextTextRef?.current) {
						if (!willBeLastRep) {
							nextTextRef.current.animate([{ opacity: 0 }, { opacity: 0.8 }, { opacity: 0 }], {
								duration: animationRate * 2,
								iterations: Infinity,
							});
						} else {
							osmdPreviewRef.current.animate([{ opacity: 0.5 }, { opacity: 0 }], {
								duration: 10,
								fill: "forwards",
							});
							nextTextRef.current.style.opacity = "0";
						}
					}
				}, animationRate);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[osmd, osmdPreview, reverse, willBeLastRep, transposition]
	);

	useEffect(() => {
		if (osmdPreview) {
			processAnimations();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [iteration]);

	useEffect(() => {
		return () => {
			window.removeEventListener("resize", resize);
		};
	}, [resize]);

	useEffect(() => {
		if (osmdPreview && osmdPreviewRef) {
			setTimeout(() => {
				if (osmdPreview) {
					osmdPreview.render();
				}
				if (osmdPreviewRef.current) {
					osmdPreviewRef.current.animate([{ opacity: 0 }, { opacity: 0.5 }], {
						duration: 500,
						fill: "forwards",
					});
					nextTextRef.current.animate([{ opacity: 0 }, { opacity: 0.8 }, { opacity: 0 }], {
						duration: 2000,
						iterations: Infinity,
					});
				}
			}, 100);
		}
	}, [nextTextRef, osmdPreview, osmdPreviewRef]);

	useEffect(() => {
		if (xml) {
			renderSheetMusic(xml);
		}
	}, [renderSheetMusic, xml]);

	useEffect(() => {
		if (!willBeLastRep) {
			const animationRate = 1000 * (60 / tempo);
			setTimeout(() => {
				if (osmdPreviewRef?.current && nextTextRef?.current) {
					osmdPreviewRef.current.animate([{ opacity: 0 }, { opacity: 0.5 }], {
						duration: 500,
						fill: "forwards",
					});
					nextTextRef.current.animate([{ opacity: 0 }, { opacity: 0.8 }, { opacity: 0 }], {
						duration: 2000,
						iterations: Infinity,
					});
				}
			}, animationRate);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [willBeLastRep]);

	useEffect(() => {
		const centerNote = (exerciseOffset ?? 0) + transposition + exerciseRange.lowNoteMidi + (exerciseRange.highNoteMidi - exerciseRange.lowNoteMidi) / 2;
		setClefType(centerNote < noteToMidi("C4") ? ClefEnum.F : ClefEnum.G);
	}, [exerciseRange.highNoteMidi, exerciseRange.lowNoteMidi, transposition, exerciseOffset]);

	// Clef
	const updateClefType = (osmd: OpenSheetMusicDisplay, clefType: ClefEnum) => {
		if (osmd) {
			osmd.Sheet.SourceMeasures[0]?.FirstInstructionsStaffEntries.forEach((entry) => {
				const clefInstruction = entry.Instructions[0];
				if (clefInstruction instanceof ClefInstruction) {
					clefInstruction.ClefType = clefType;
				}
			});
			osmd.render();
		}
	};

	useEffect(() => {
		if (osmd) {
			updateClefType(osmd, clefType);
		}
	}, [clefType, osmd]);

	useEffect(() => {
		if (osmdPreview) {
			updateClefType(osmdPreview, clefType);
		}
	}, [clefType, osmdPreview]);

	return {
		osmd,
		osmdPreview,
	};
};

export const [RenderProvider, useRenderContext] = constate(useRender);
