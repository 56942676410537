import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../common/services/firebase";

interface UserSignIn {
	email: string;
	password: string;
}

const signin = ({ email, password }: UserSignIn) => {
	return signInWithEmailAndPassword(auth, email, password)
		.then((_userCredential) => {
			// const user = userCredential.user;
		})
		.catch((error) => {
			console.error(error);
			// const errorCode = error.code;
			// const errorMessage = error.message;
		});
};

export default signin;
