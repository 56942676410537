import { IonContent, IonHeader, IonList, IonPage, IonSearchbar, IonTitle, IonToolbar } from "@ionic/react";
import { useAtomValue } from "jotai";
import { useState } from "react";
import userAtom from "../user/state";
import { deleteSession } from "./actions/deleteSession";
import { renameSession } from "./actions/renameSession";
import Sessions from "./components/Sessions";
import sessionsAtom from "./state";

const SessionsPage = () => {
	const sessions = useAtomValue(sessionsAtom);
	const user = useAtomValue(userAtom);
	const [searchText, setSearchText] = useState("");

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Sessions</IonTitle>
				</IonToolbar>
				<IonToolbar>
					<IonSearchbar animated={true} value={searchText} onIonInput={(e) => setSearchText(e.detail.value!)}></IonSearchbar>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				{user && (
					<IonList style={{ marginTop: "0.3rem" }}>
						<Sessions
							sessions={sessions.filter((s) => s.name.toLowerCase().includes(searchText.toLowerCase())).sort((a, b) => b.createdAt - a.createdAt)}
							deleteSession={(playlistId: string) => {
								deleteSession(playlistId);
							}}
							renameSession={(playlistId: string, name: string) => {
								renameSession(playlistId, name);
							}}
						/>
					</IonList>
				)}
			</IonContent>
		</IonPage>
	);
};

export default SessionsPage;
