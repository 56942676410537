import { addDoc, collection } from "firebase/firestore";
import { auth, db } from "../../../common/services/firebase";
import { IExerciseUpload } from "../interfaces";

interface IUploadExerciseParams {
	metadata: IExerciseUpload;
}

const uploadExercise = async ({ metadata }: IUploadExerciseParams): Promise<string | undefined> => {
	const user = auth.currentUser;
	if (user) {
		const docRef = await addDoc(collection(db, "exercises"), {
			...metadata,
			createdBy: user.uid,
		});
		return docRef.id;
	}
	return;
};

export default uploadExercise;
