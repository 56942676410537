import { doc, updateDoc } from "@firebase/firestore";
import { auth, db } from "../../../common/services/firebase";

const setSessionNote = async (sessionId: string, note: string): Promise<void> => {
	const user = auth.currentUser;
	if (user) {
		const docRef = doc(db, "users", user.uid, "sessions", sessionId);
		await updateDoc(docRef, { note });
	}
};

export default setSessionNote;
