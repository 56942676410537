import { atom } from "jotai";
import storage from "../../../common/services/storage";

const downloadedExerciseIdsAtom = atom<string[]>([]);

downloadedExerciseIdsAtom.onMount = (setAtom) => {
	storage.keys().then(setAtom);
};

export default downloadedExerciseIdsAtom;
