import { IonIcon, IonLabel, IonReorder, useIonPopover } from "@ionic/react";
import { ellipsisVertical } from "ionicons/icons";
import StyledIonItem from "../../../common/components/StyledIonItem";
import { IPlaylist } from "../interfaces";
import PlaylistItemOptions from "./PlaylistItemOptions";

interface Props {
	playlist: IPlaylist;
	onDelete: () => void;
	onRename: (name: string) => void;
	onClick: () => void;
	isReordering?: boolean;
}

const PlaylistItem = ({ playlist, onDelete, onRename, onClick, isReordering }: Props) => {
	const [presentPopover, dismiss] = useIonPopover(PlaylistItemOptions, { onHide: () => dismiss(), onDelete, onRename });

	return (
		<StyledIonItem lines="none" detail={false} key={playlist.name} onClick={onClick}>
			<IonLabel>
				<h2>{playlist.name}</h2>
				{/* <p>Test</p> */}
			</IonLabel>
			{/* <IonNote slot="end">4.99</IonNote> */}
			{isReordering ? (
				<IonReorder slot="end"></IonReorder>
			) : (
				<IonIcon
					icon={ellipsisVertical}
					slot="end"
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						presentPopover({
							event: e.nativeEvent,
						});
					}}
				/>
			)}
		</StyledIonItem>
	);
};

export default PlaylistItem;
