import { IonButton, IonContent, IonIcon } from "@ionic/react";
import { checkmarkCircle } from "ionicons/icons";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { IVocalRange } from "../../../common/interfaces";
import setVoiceType from "../actions/setVoiceType";
import { IVoiceType, VOICE_TYPES } from "../constants/voiceTypes";
import useVocalRange from "../hooks/useVocalRange";
import voiceTypeAtom from "../state";

const StyledIonContent = styled(IonContent)`
	display: flex;
	flex-direction: column;
	padding: 2rem;
	* {
		color: grey;
	}
	text-align: center;

	ion-button {
		padding-left: 1rem;
		padding-right: 1rem;
		width: 100%;
	}
`;

interface Props {
	onDismiss: () => void;
}

const VoiceTypeSelectionPage = ({ onDismiss }: Props) => {
	const voiceType = useAtomValue(voiceTypeAtom);

	const { showVocalRangeSelector, vocalRange } = useVocalRange({
		vocalRange: voiceType as IVocalRange,
	});

	const [selectedVoiceType, setSelectedVoiceType] = useState<IVoiceType>();

	useEffect(() => {
		if (selectedVoiceType) {
			setVoiceType(selectedVoiceType).then(onDismiss);
		}
	}, [onDismiss, selectedVoiceType]);

	useEffect(() => {
		if (vocalRange) {
			setVoiceType({
				key: "custom",
				value: "Custom",
				...vocalRange,
			});
			onDismiss();
		}
	}, [onDismiss, vocalRange]);

	return (
		<StyledIonContent fullscreen>
			<h1>What’s your voice type?</h1>
			{/* <IonButton
				color="medium"
				fill="outline"
				onClick={() => {
					next();
				}}
			>
				I don't know
			</IonButton> */}
			{VOICE_TYPES.map((option) => (
				<IonButton
					key={option.key}
					color="medium"
					fill="outline"
					onClick={() => {
						setSelectedVoiceType(option);
					}}
				>
					{option.value} ({option.lowNote} - {option.highNote})
					{voiceType?.key === option.key && <IonIcon icon={checkmarkCircle} color="success" style={{ position: "absolute", right: 2 }} />}
				</IonButton>
			))}
			<IonButton
				color="medium"
				fill="outline"
				onClick={() => {
					showVocalRangeSelector();
				}}
			>
				Custom{" "}
				{voiceType?.key === "custom" && (
					<>
						({voiceType.lowNote} - {voiceType.highNote})
						<IonIcon icon={checkmarkCircle} color="success" style={{ position: "absolute", right: 2 }} />
					</>
				)}
			</IonButton>
		</StyledIonContent>
	);
};

export default VoiceTypeSelectionPage;
