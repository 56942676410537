import { useCallback, useEffect, useState } from "react";

interface Props {
	audioUrl: string;
	seeking: boolean;
}

const useAudio = ({ audioUrl, seeking }: Props) => {
	const [audio] = useState(new Audio(audioUrl));

	const [loading, setLoading] = useState(true);
	const [playing, setPlaying] = useState(false);
	const [duration, setDuration] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);

	const play = useCallback(() => {
		if (audio) {
			audio.play();
			setPlaying(true);
		}
	}, [audio]);

	const pause = useCallback(() => {
		if (audio) {
			audio.pause();
			setPlaying(false);
		}
	}, [audio]);

	const seek = useCallback(
		(seconds: number) => {
			if (audio) {
				audio.currentTime = seconds;
			}
		},
		[audio]
	);

	useEffect(() => {
		if (audio) {
			audio.onloadeddata = () => {
				setDuration(audio.duration);
				setLoading(false);
			};
			audio.onended = () => {
				seek(0);
				setPlaying(false);
			};
			audio.ontimeupdate = () => setCurrentTime(audio.currentTime);

			audio.load();
		}
	}, [audio, seek]);

	useEffect(() => {
		return () => {
			pause();
		};
	}, [pause]);

	return {
		audio,
		play,
		pause,
		togglePlayback: () => {
			if (playing) pause();
			else play();
		},
		seek,
		duration,
		currentTime,
		loading,
		seeking,
		playing,
	};
};

export default useAudio;
