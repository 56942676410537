import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../common/services/firebase";
import { auth } from "./../../../common/services/firebase";

export const renameSession = async (sessionId: string, name: string): Promise<void> => {
	const user = auth.currentUser;
	if (user) {
		await updateDoc(doc(db, "users", user.uid, "sessions", sessionId), {
			name,
		});
	}
};
