import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "../../../common/services/firebase";
import setUser from "./setUser";

interface UserSignUp {
	name: string;
	email: string;
	password: string;
}

/**
 *
 * @param {*} userInfo.name
 * @param {*} userInfo.email
 * @param {*} userInfo.password
 */
const signup = async ({ name, email, password }: UserSignUp) => {
	try {
		const userCredential = await createUserWithEmailAndPassword(auth, email, password);

		// Signed in
		const user = userCredential.user;
		await setUser({
			name,
		});

		const docRef = doc(db, "users", `${user.uid}`);
		await setDoc(docRef, {
			name,
		});
	} catch (error: any) {
		const errorCode = error.code;
		const errorMessage = error.message;
		console.error(errorCode, errorMessage);
		// ..
	}
};

export default signup;
