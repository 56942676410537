import { IVocalRange } from "../../../common/interfaces";
import { noteToMidi } from "../../../common/utils";

export interface IVoiceType extends IVocalRange {
	key: string;
	value: string;
}

export const VOICE_TYPES: IVoiceType[] = [
	{
		key: "soprano",
		value: "Soprano",
		highNote: "C6",
		lowNote: "C4",
	},
	{
		key: "mezzo_soprano",
		value: "Mezzo-Soprano",
		highNote: "A5",
		lowNote: "A3",
	},
	{
		key: "alto",
		value: "Alto",
		highNote: "F5",
		lowNote: "F3",
	},
	{
		key: "tenor",
		value: "Tenor",
		highNote: "C5",
		lowNote: "C3",
	},
	{
		key: "baritone",
		value: "Baritone",
		highNote: "A4",
		lowNote: "A2",
	},
	{
		key: "bass",
		value: "Bass",
		highNote: "E4",
		lowNote: "E2",
	},
].map((voiceType) => {
	const highNoteMidi = noteToMidi(voiceType.highNote);
	const lowNoteMidi = noteToMidi(voiceType.lowNote);

	return {
		...voiceType,
		highNoteMidi,
		lowNoteMidi,
	};
});
