import { Drivers, Storage } from "@ionic/storage";

const s = new Storage({
	name: "__cantare",
	driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
});

s.create();

const keys = () => s.keys();
const set = (key: string, val: any) => s.set(key, val);
const get = (key: string) => s.get(key);
const remove = (key: any) => s.remove(key);
const clear = () => s.clear();

const storage = {
	keys,
	get,
	set,
	remove,
	clear,
};

export default storage;
