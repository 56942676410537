import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot, Unsubscribe } from "firebase/firestore";
import { atom } from "jotai";
import { auth, db } from "../../common/services/firebase";
import { Profile, User } from "./interfaces";

const INIT = Symbol(); // or anything that doesn't conflict with data.
const DESTROY = Symbol();

const userAtom = atom<User | undefined | null>(undefined);

userAtom.onMount = (setAtom) => {
	onAuthStateChanged(auth, (user) => {
		if (user) {
			setAtom(() => ({
				id: user.uid!,
				name: user.displayName!,
				email: user.email!,
			}));
		} else {
			setAtom(() => null);
		}
	});
};

export default userAtom;

export const isAuthenticatedAtom = atom((get) => !!get(userAtom)?.id);
export const userIdAtom = atom((get) => get(userAtom)?.id);

const defaultUserProfileAtom = atom<Profile | undefined>(undefined);

export const userProfileAtom = atom(
	(get) => get(defaultUserProfileAtom),
	(get, set, arg) => {
		let unsubscribe: Unsubscribe | undefined;
		if (arg === INIT) {
			const userId = get(userAtom)?.id;

			unsubscribe = onSnapshot(doc(db, `users/${userId}`), { includeMetadataChanges: true }, (doc) => {
				set(defaultUserProfileAtom, doc.data());
			});
		} else if (arg === DESTROY) {
			if (unsubscribe) {
				unsubscribe();
			}
		} else {
			set(defaultUserProfileAtom, arg as Profile);
		}
	}
);

userProfileAtom.onMount = (dispatch) => {
	dispatch(INIT);
};
