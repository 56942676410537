import constate from "constate";
import { BasicAudioPlayer, LinearTimingSource, PlaybackManager } from "osmd-extended";
import { useCallback, useEffect, useState } from "react";
import { useExerciseSettingsContext } from "./exerciseSettings";
import { useOSMDContext } from "./osmd";

export enum AudioPlayerState {
	LOADING,
	RUNNING,
	PAUSED,
	STOPPED,
	ERROR,
}

const timingSource = new LinearTimingSource();
const playbackManager = new PlaybackManager(timingSource, undefined as any, new BasicAudioPlayer(), {
	MessageOccurred: (err: string) => {
		console.error("Errs", err);
	},
});

const usePlayback = () => {
	const { osmd, setIteration, setCurrentNote, reverse, iteration, setTransposition, reset } = useOSMDContext();
	const { tempo, range, exerciseOffset, vocalPlaybackDisabled } = useExerciseSettingsContext();
	const [audioState, setAudioState] = useState<AudioPlayerState>(AudioPlayerState.LOADING);

	useEffect(() => {
		if (osmd) {
			osmd.Sheet.Instruments[0].Audible = !vocalPlaybackDisabled;
		}
	}, [vocalPlaybackDisabled, osmd]);

	useEffect(() => {
		if (audioState === AudioPlayerState.RUNNING) {
			stop();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [range]);

	const selectionEndReached = useCallback(() => {
		playbackManager.pause();
		playbackManager.reset();
		setTimeout(async () => {
			playbackManager.play();
		}, 50);
		setIteration((iteration: number) => iteration + 1);
	}, [setIteration]);

	const notesPlaybackEventOccurred = useCallback(
		(o: any[]) => {
			try {
				const visibleNotes = o.filter((o) => o.ParentNote.voiceEntry.ParentVoice.Visible);
				if (visibleNotes.length > 0) {
					const midi = visibleNotes[0].MidiKey;
					setCurrentNote(midi);
				}
			} catch (e) {
				console.error(e);
			}
		},
		[setCurrentNote]
	);

	const updatePlaybackState = useCallback(async () => {
		if (osmd) {
			timingSource.reset();
			timingSource.pause();

			timingSource.Settings = osmd.Sheet.SheetPlaybackSetting;

			if (playbackManager) {
				playbackManager.initialize(osmd.Sheet.MusicPartManager);

				osmd.cursors.forEach((cursor) => playbackManager?.addListener(cursor));

				// (playbackManager as any).listeners.clear();
				playbackManager.addListener({
					cursorPositionChanged: (timestamp: any, data: any) => {},
					pauseOccurred: (o: any) => {},
					selectionEndReached,
					resetOccurred: (o: any) => {},
					notesPlaybackEventOccurred,
				});
				playbackManager.reset();

				playbackManager.setSound(0, 0); //  Sets to piano sound

				osmd.PlaybackManager = playbackManager;
			}
		}
	}, [notesPlaybackEventOccurred, osmd, selectionEndReached]);

	useEffect(() => {
		playbackManager.DoPlayback = true;
		playbackManager.DoPreCount = false;
		playbackManager.PreCountMeasures = 0; // note that DoPreCount has to be true for a precount to happen

		(playbackManager as any).handleEndReached = () => {
			playbackManager.reset();
		};
		return () => {
			playbackManager.reset();
			playbackManager.pause();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (osmd) {
			updatePlaybackState().then(() => {
				setAudioState(AudioPlayerState.STOPPED);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [osmd]);

	const play = async () => {
		if (audioState === AudioPlayerState.STOPPED) {
			setIteration(0);
		}
		setAudioState(AudioPlayerState.RUNNING);
		playbackManager.bpmChanged(tempo, true);
		playbackManager.play();
	};

	const pause = async () => {
		setAudioState(AudioPlayerState.PAUSED);
		playbackManager.pause();
	};

	const stop = useCallback(async () => {
		if (osmd) {
			playbackManager.reset();
			pause();
			reset();
			setAudioState(AudioPlayerState.STOPPED);
		}
	}, [osmd, reset]);

	const previous = async () => {
		if (osmd) {
			playbackManager?.reset();
			setTransposition((transposition: number) => transposition - 1);
		}
	};

	const next = async () => {
		if (osmd) {
			playbackManager?.reset();
			setTransposition((transposition: number) => transposition + 1);
		}
	};

	useEffect(() => {
		if (iteration > 0 && osmd) {
			if (reverse && osmd.Sheet.Transpose <= (exerciseOffset ?? 0)) {
				stop();
			} else {
				// Update the transposition
				setTransposition((transposition: number) => transposition + (reverse ? -1 : 1));
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [iteration]);

	/*
	 * Stop when exercise range changes
	 */
	useEffect(() => {
		if (exerciseOffset) {
			stop();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [exerciseOffset]);

	useEffect(() => {
		playbackManager.bpmChanged(tempo, true);
		if (!osmd) return;
		for (const measure of osmd.Sheet.SourceMeasures) {
			measure.TempoInBPM = tempo;
		}
		if (audioState === AudioPlayerState.RUNNING || audioState === AudioPlayerState.PAUSED) {
			stop();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [osmd, tempo]);

	return {
		audioState,
		play,
		stop,
		pause,
		previous,
		next,
	};
};

export const [PlaybackProvider, usePlaybackContext] = constate(usePlayback);
