import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonInput, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToolbar, useIonModal, useIonToast } from "@ionic/react";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import VoiceTypeSelectionPage from "../../voice-type-selection/pages/VoiceTypeSelectionPage";
import setUser from "../actions/setUser";
import signout from "../actions/signout";
import { User } from "../interfaces";
import userAtom from "../state";

const ProfilePage = () => {
	const user = useAtomValue(userAtom);
	const [draftUser, setDraftUser] = useState<Partial<User>>();

	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const [presentModal, dismissModal] = useIonModal(VoiceTypeSelectionPage, {
		onDismiss: () => {
			dismissModal();
		},
	});

	const [presentToast] = useIonToast();

	useEffect(() => {
		if (user) {
			setDraftUser(user);
		}
	}, [user]);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Profile</IonTitle>
					{user && (
						<IonButtons slot="end">
							<IonButton
								onClick={async () => {
									if (draftUser) {
										await setUser({
											name: draftUser.name,
											email: draftUser.email,
										});
									}
									presentToast({
										color: "success",
										message: "Your changes have been saved.",
										duration: 2000,
										position: "top",
									});
								}}
							>
								Save
							</IonButton>
						</IonButtons>
					)}
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				{draftUser && (
					<IonList>
						<IonItem lines="none">
							<IonLabel position="stacked">Name</IonLabel>
							<IonInput aria-label="name" value={draftUser.name} type="text" placeholder="Name" onIonInput={(e) => setDraftUser({ ...draftUser, name: e.detail.value! })}></IonInput>
						</IonItem>
						<IonItem lines="none">
							<IonLabel position="stacked">Email</IonLabel>
							<IonInput aria-label="email" value={draftUser.email} type="email" placeholder="Email" onIonInput={(e) => setDraftUser({ ...draftUser, email: e.detail.value! })}></IonInput>
						</IonItem>
						<IonItem lines="none">
							<IonLabel position="stacked">New Password</IonLabel>
							<IonInput aria-label="password" value={password} type="password" placeholder="New password" onIonInput={(e) => setPassword(e.detail.value!)}></IonInput>
						</IonItem>
						{password && (
							<IonItem lines="none">
								<IonLabel position="stacked">Confirm New Password</IonLabel>
								<IonInput
									aria-label="password-confirm"
									value={confirmPassword}
									type="password"
									placeholder="Confirm new password"
									onIonInput={(e) => setConfirmPassword(e.detail.value!)}
								></IonInput>
							</IonItem>
						)}
					</IonList>
				)}
			</IonContent>

			<IonFooter>
				<IonButton
					expand="block"
					fill="clear"
					onClick={() => {
						presentModal();
					}}
				>
					Update Vocal Range
				</IonButton>
				<IonButton
					fill="clear"
					color="danger"
					expand="block"
					onClick={() => {
						signout();
					}}
				>
					Sign Out
				</IonButton>
			</IonFooter>
		</IonPage>
	);
};

export default ProfilePage;
