import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonPage,
	IonReorder,
	IonReorderGroup,
	IonTitle,
	IonToolbar,
	ItemReorderEventDetail,
} from "@ionic/react";
import { bookmark } from "ionicons/icons";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import EmptyState from "../../common/components/EmptyState";
import exercisesAtom from "../exercises/atoms/exercisesAtom";
import { removeExerciseIdFromPlaylist, updateExerciseIdsInPlaylist } from "../playlists/actions";
import userAtom from "../user/state";
import { playlistAtom, playlistIdAtom } from "./state";

interface Props {
	id: string;
}

const StyledIonItem = styled(IonItem)`
	padding-top: 0.3rem;
	padding-bottom: 0.3rem;
	border-bottom: 1px solid #e0e0e0;
`;

const PlaylistPage = () => {
	const params = useParams<Props>();
	const setPlaylistId = useSetAtom(playlistIdAtom);
	const playlist = useAtomValue(playlistAtom);
	const exercises = useAtomValue(exercisesAtom);
	const user = useAtomValue(userAtom);

	useEffect(() => {
		if (params.id) {
			setPlaylistId(params.id);
		}
	}, [params, setPlaylistId]);

	const history = useHistory();

	const [isReordering, setIsReordering] = useState(false);
	const [exerciseIds, setExerciseIds] = useState<string[]>(playlist?.exerciseIds || []);

	useEffect(() => {
		if (playlist) {
			setExerciseIds(playlist.exerciseIds);
		}
	}, [playlist]);

	const onReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
		const updatedExerciseIds = [...exerciseIds];
		updatedExerciseIds.splice(event.detail.from, 1);
		updatedExerciseIds.splice(event.detail.to, 0, exerciseIds[event.detail.from]);
		setExerciseIds(updatedExerciseIds);

		event.detail.complete();
	};

	const saveReorder = () => {
		if (!user) return;

		updateExerciseIdsInPlaylist(user.id, params.id, exerciseIds);
		setIsReordering(false);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" defaultHref="/" />
					</IonButtons>
					<IonButtons slot="end">
						{exerciseIds.length > 1 &&
							(isReordering ? (
								<IonButton onClick={saveReorder}>Done</IonButton>
							) : (
								<IonButton
									onClick={() => {
										setIsReordering(true);
									}}
								>
									Reorder
								</IonButton>
							))}
					</IonButtons>
					<IonTitle>{playlist?.name}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<IonList style={{ marginTop: "0.3rem" }}>
					<IonReorderGroup disabled={!isReordering} onIonItemReorder={onReorder}>
						{playlist &&
							playlist.exerciseIds.map((exerciseId) => (
								<StyledIonItem
									lines="none"
									detail={false}
									onClick={(e) => {
										history.push(`/exercise/${exerciseId}`);
									}}
									key={exerciseId}
								>
									<IonLabel>
										<h2>{exercises.find((exercise) => exercise.id === exerciseId)?.name}</h2>
									</IonLabel>
									{isReordering ? (
										<IonReorder slot="end"></IonReorder>
									) : (
										<IonIcon
											icon={bookmark}
											slot="end"
											onClick={(e) => {
												e.stopPropagation();
												e.preventDefault();
												if (!user) return;
												removeExerciseIdFromPlaylist(user.id, playlist, exerciseId);
											}}
										/>
									)}
								</StyledIonItem>
							))}
					</IonReorderGroup>
				</IonList>
				{!playlist?.exerciseIds.length && <EmptyState description="No exercises have been added yet." />}
			</IonContent>
		</IonPage>
	);
};

export default PlaylistPage;
