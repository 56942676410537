import { IonButton, IonButtons, IonCheckbox, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { checkmark, close } from "ionicons/icons";
import { useAtom, useAtomValue } from "jotai";
import { useEffect } from "react";
import userAtom from "../user/state";
import { modifyExerciseInPlaylists } from "./actions";
import playlistsAtom from "./state";

interface AddToPlaylistsModalProps {
	isOpen: boolean;
	selectedPlaylists: Set<string>;
	setSelectedPlaylists: (selectedPlaylists: Set<string>) => void;
	onDismiss: () => void;
	onPlaylistToggle: (playlistId: string) => void;
	exerciseId: string;
}

const AddToPlaylistsModal: React.FC<AddToPlaylistsModalProps> = ({ isOpen, selectedPlaylists, setSelectedPlaylists, onDismiss, onPlaylistToggle, exerciseId }) => {
	const [playlists] = useAtom(playlistsAtom);
	const user = useAtomValue(userAtom);

	const modifyPlaylists = () => {
		if (!user) return;
		modifyExerciseInPlaylists(user.id, playlists, selectedPlaylists, exerciseId);
		onDismiss();
	};

	useEffect(() => {
		// Update selectedPlaylists
		if (playlists) {
			const newSelectedPlaylists = new Set<string>();
			playlists.forEach((playlist) => {
				if (playlist.exerciseIds.includes(exerciseId)) {
					newSelectedPlaylists.add(playlist.id);
				}
			});
			setSelectedPlaylists(newSelectedPlaylists);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [playlists, exerciseId]);

	return (
		<IonModal isOpen={isOpen} onDidDismiss={onDismiss}>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Add to Routines</IonTitle>
					{user && (
						<>
							<IonButtons slot="start">
								<IonButton
									onClick={() => {
										onDismiss();
									}}
								>
									<IonIcon slot="icon-only" icon={close} />
								</IonButton>
							</IonButtons>
							<IonButtons slot="end">
								<IonButton onClick={modifyPlaylists}>
									<IonIcon slot="icon-only" icon={checkmark} />
								</IonButton>
							</IonButtons>
						</>
					)}
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<IonList>
					{playlists && playlists.length ? (
						playlists.map((playlist) => (
							<IonItem key={playlist.id}>
								<IonCheckbox checked={selectedPlaylists.has(playlist.id)} onIonChange={() => onPlaylistToggle(playlist.id)}>
									{playlist.name}
								</IonCheckbox>
							</IonItem>
						))
					) : (
						<IonItem lines="none">
							<IonLabel className="ion-text-center">No results found</IonLabel>
						</IonItem>
					)}
				</IonList>
			</IonContent>
			{/* <IonButton onClick={onDismiss}>Close</IonButton> */}
		</IonModal>
	);
};

export default AddToPlaylistsModal;
