import { collection, onSnapshot, query, Unsubscribe } from "firebase/firestore";
import { atom } from "jotai";
import { db } from "../../common/services/firebase";
import userAtom from "../user/state";
import { IPlaylist } from "./interfaces";

const INIT = Symbol(); // or anything that doesn't conflict with data.
const DESTROY = Symbol();

// playlistsAtom.onMount = (setAtom) => {
// 	const q = query(collection(db, "playlists"));
// 	onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
// 		const newExercises = snapshot.docs.map((doc) => ({ ...(doc.data() as IExercise), id: doc.id }));
// 		setAtom(newExercises);
// 	});
// };

const defaultPlaylistsAtom = atom<IPlaylist[]>([]);

const playlistsAtom = atom(
	(get) => get(defaultPlaylistsAtom),
	(get, set, arg) => {
		let unsubscribe: Unsubscribe | undefined;
		if (arg === INIT) {
			const userId = get(userAtom)?.id;
			const q = query(collection(db, `users/${userId}/playlists`));

			unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
				const newPlaylists = snapshot.docs.map((doc) => ({ ...(doc.data() as IPlaylist), id: doc.id })).sort((a, b) => a.index - b.index);
				set(defaultPlaylistsAtom, newPlaylists);
			});
		} else if (arg === DESTROY) {
			if (unsubscribe) {
				unsubscribe();
			}
		} else {
			set(defaultPlaylistsAtom, arg as IPlaylist[]);
		}
	}
);

playlistsAtom.onMount = (dispatch) => {
	dispatch(INIT);

	return () => {
		dispatch(DESTROY);
	};
};

export default playlistsAtom;
