import { IonBadge, IonIcon, IonLabel, useIonModal, useIonPopover } from "@ionic/react";
import { ellipsisVertical } from "ionicons/icons";
import ItemOptions from "../../../common/components/ItemOptions";
import StyledIonItem from "../../../common/components/StyledIonItem";
import SessionPage from "../../session/pages/SessionPage";
import { ISession } from "../interfaces";

interface Props {
	session: ISession;
	onDelete: () => void;
	onRename: (name: string) => void;
}

const SessionItem = ({ session, onDelete, onRename }: Props) => {
	const [presentPopover, dismiss] = useIonPopover(ItemOptions, { onHide: () => dismiss(), onDelete, onRename });

	const [presentModal, dismissModal] = useIonModal(SessionPage, {
		onDismiss: () => {
			dismissModal();
		},
		...session,
	});

	return (
		<StyledIonItem
			lines="none"
			detail={false}
			onClick={() => {
				presentModal();
			}}
			key={session.id}
		>
			<IonLabel>
				<h2>
					{session.name} {session.phonemePattern && `(${session.phonemePattern})`}
				</h2>
				<p>
					<IonBadge>{session.tempo} BPM</IonBadge>
				</p>
			</IonLabel>
			{/* <IonNote slot="end">4.99</IonNote> */}
			<IonIcon
				icon={ellipsisVertical}
				slot="end"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					presentPopover({
						event: e.nativeEvent,
					});
				}}
			/>
		</StyledIonItem>
	);
};

export default SessionItem;
