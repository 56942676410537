import { useIonPicker, useIonToast } from "@ionic/react";
import { useState } from "react";
import { INote, IVocalRange } from "../../../common/interfaces";
import { midiToNote } from "../../../common/utils";
import { NOTE_OPTIONS } from "./../constants/defaultVocalRange";

interface Props {
	vocalRange?: IVocalRange;
}

const useVocalRange = ({ vocalRange }: Props) => {
	const [notes] = useState<INote[]>(NOTE_OPTIONS.map((midi: number) => ({ value: midi, text: midiToNote(midi) } as INote)));
	const [range, setRange] = useState<IVocalRange>();

	const [present] = useIonPicker();
	const [presentToast] = useIonToast();

	const showVocalRangeSelector = () => {
		const lowNoteSelectedIdx = notes.findIndex((option) => range?.lowNoteMidi === option.value || vocalRange?.lowNoteMidi === option.value);
		const highNoteSelectedIdx = notes.findIndex((option) => range?.highNoteMidi === option.value || vocalRange?.highNoteMidi === option.value);

		present({
			buttons: [
				{
					text: "Cancel",
					handler: () => {},
				},
				{
					text: "Confirm",
					handler: (selected) => {
						const lowNote = selected.lowNote as INote;
						const highNote = selected.highNote as INote;

						if (selected.lowNote.value >= selected.highNote.value) {
							presentToast({
								color: "warning",
								message: "Your low note must be greater than your high note!",
								duration: 3000,
							});
						} else {
							setRange({
								lowNote: lowNote.text,
								highNote: highNote.text,
								lowNoteMidi: lowNote.value,
								highNoteMidi: highNote.value,
							});
						}
					},
				},
			],
			columns: [
				{
					name: "lowNote",
					options: notes.map(({ text, value }) => ({ text, value })),
					selectedIndex: lowNoteSelectedIdx > -1 ? lowNoteSelectedIdx : notes.length - 1,
				},
				{
					name: "hyphen",
					options: [{ text: "-", value: "-" }],
					selectedIndex: 0,
					columnWidth: "8px",
				},
				{
					name: "highNote",
					options: notes.map(({ text, value }) => ({ text, value })),
					selectedIndex: highNoteSelectedIdx > -1 ? highNoteSelectedIdx : 0,
				},
			],
		});
	};

	return {
		vocalRange: range,
		showVocalRangeSelector,
	};
};

export default useVocalRange;
