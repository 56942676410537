import { getDownloadURL, ref } from "firebase/storage";
import { firebaseStorage } from "../../../common/services/firebase";

const getXML = async (filename: string, onProgress?: (progress: number) => void) => {
	const pathReference = ref(firebaseStorage, `xml/${filename}`);
	const url = await getDownloadURL(pathReference);

	const response = await fetch(url);
	if (response?.body) {
		const reader = response.body.getReader();
		const contentLength = response.headers.get("Content-Length");

		// Step 3: read the data
		let receivedLength = 0; // received that many bytes at the moment
		let chunks = []; // array of received binary chunks (comprises the body)
		while (true) {
			const { done, value } = await reader.read();

			if (done) {
				break;
			}

			if (value) {
				chunks.push(value);
				receivedLength += value.length;

				if (contentLength && onProgress) {
					onProgress(Math.round((100 * receivedLength) / Number(contentLength)));
				}
			}
		}

		// Step 4: concatenate chunks into single Uint8Array
		let chunksAll = new Uint8Array(receivedLength); // (4.1)
		let position = 0;
		for (let chunk of chunks) {
			if (chunk) {
				chunksAll.set(chunk, position); // (4.2)
				position += chunk.length;
			}
		}

		// Step 5: decode into a string
		const score = new TextDecoder("utf-8").decode(chunksAll);
		return score;
	} else {
		return undefined;
	}
	// const score = await response.text();
	// return score;
};

export default getXML;
