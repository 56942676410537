import { IonReorderGroup, ItemReorderEventDetail } from "@ionic/react";
import { useHistory } from "react-router";
import EmptyState from "../../../common/components/EmptyState";
import { IPlaylist } from "../interfaces";
import PlaylistItem from "./PlaylistItem";

interface Props {
	playlists: IPlaylist[];
	createPlaylist: () => void;
	deletePlaylist: (id: string) => void;
	renamePlaylist: (id: string, name: string) => void;
	isReordering?: boolean;
	onReorder?: (event: CustomEvent<ItemReorderEventDetail>) => void;
}

const Playlists = ({ playlists, createPlaylist, deletePlaylist, renamePlaylist, isReordering, onReorder }: Props) => {
	const history = useHistory();

	return (
		<>
			{playlists?.length ? (
				<IonReorderGroup disabled={!isReordering} onIonItemReorder={onReorder}>
					{playlists.map((playlist) => (
						<PlaylistItem
							key={playlist.id}
							playlist={playlist}
							onClick={() => {
								history.push(`/routine/${playlist.id}`);
							}}
							onDelete={() => {
								deletePlaylist(playlist.id);
							}}
							onRename={(name) => {
								renamePlaylist(playlist.id, name);
							}}
							isReordering={isReordering}
						/>
					))}
				</IonReorderGroup>
			) : (
				<EmptyState action={createPlaylist} actionText="Create a new playlist" description="No playlists available" />
			)}
		</>
	);
};

export default Playlists;
