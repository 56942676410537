import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { auth, db } from "../../../common/services/firebase";
import { IExerciseSettings } from "../../exercise/interfaces/exerciseSettings";
import { ISession } from "./../interfaces";

export const createSession = async (exerciseSettings: IExerciseSettings, name: string, phonemePattern?: string): Promise<ISession | undefined> => {
	const user = auth.currentUser;

	if (user) {
		const data = {
			createdAt: new Date().getTime(),
			name,
			...exerciseSettings,
			...(!!phonemePattern && { phonemePattern }),
		};
		const docRef = await addDoc(collection(db, "users", user.uid, "sessions"), data);

		await setDoc(doc(db, "users", user.uid, "history", exerciseSettings.exerciseId), {
			...exerciseSettings,
			updatedAt: new Date(),
			...(!!phonemePattern && { phonemePattern }),
		});

		return { id: docRef.id, ...data };
	}

	return undefined;
};
