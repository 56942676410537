import { IonItemDivider } from "@ionic/react";
import { useEffect, useState } from "react";
import EmptyState from "../../../common/components/EmptyState";
import { IDateBins, ISession } from "../interfaces";
import SessionItem from "./SessionItem";

interface Props {
	sessions: ISession[];
	deleteSession: (id: string) => void;
	renameSession: (id: string, name: string) => void;
}

const Sessions = ({ sessions, deleteSession, renameSession }: Props) => {
	const [dateBins, setDateBins] = useState<IDateBins | null>(null);

	useEffect(() => {
		if (sessions?.length) {
			const dateBins: IDateBins = {};
			sessions.forEach((session) => {
				const date = new Date(session.createdAt).toLocaleDateString();
				if (!(date in dateBins)) {
					dateBins[date] = [];
				}
				dateBins[date].push(session);
			});
			setDateBins(dateBins);
		} else {
			setDateBins(null);
		}
	}, [sessions]);

	return (
		<>
			{dateBins ? (
				Object.keys(dateBins)
					.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
					.map((date) => {
						return (
							<div key={date}>
								<IonItemDivider>{date}</IonItemDivider>
								{dateBins[date].map((session) => (
									<SessionItem
										key={session.id}
										session={session}
										onDelete={() => {
											deleteSession(session.id);
										}}
										onRename={(name) => {
											renameSession(session.id, name);
										}}
									/>
								))}
							</div>
						);
					})
			) : (
				<EmptyState description="No sessions available" />
			)}
		</>
	);
};

export default Sessions;
