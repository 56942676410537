import { collection, onSnapshot, query } from "firebase/firestore";
import { atom } from "jotai";
import { db } from "../../common/services/firebase";
import userAtom from "../user/state";

interface IVotes {
	[key: string]: number;
}

const defaultVotesAtom = atom<IVotes>({});

const votesAtom = atom(
	(get) => get(defaultVotesAtom),
	(get, set, sessionId) => {
		if (!sessionId) return;
		const userId = get(userAtom)?.id;
		const q = query(collection(db, `users/${userId}/sessions/${sessionId}/votes`));

		onSnapshot(q, (snapshot) => {
			const result: IVotes = {};
			snapshot.docs.forEach((doc) => {
				result[doc.id] = doc.data().vote;
			});
			set(defaultVotesAtom, result);
		});
	}
);

export default votesAtom;
