import { IonRange } from "@ionic/react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import msToTime from "../../../common/utils/msToTime";

interface TrackProgressProps {
	currentTime: number;
	duration: number;
	onSeek: (time: number) => void;
	seeking: boolean;
	setSeeking: (seeking: boolean) => void;
}

const TrackProgressTime = styled.div`
	display: flex;
	margin-top: -1rem;
	font-size: 14px;
`;

const TrackProgressTimeCurrent = styled.div`
	flex: 1;
	color: #aaa;
`;

const TrackProgressTimeLeft = styled.div`
	color: #aaa;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding-right: 1rem;
`;

const StyledIonRange = styled(IonRange)`
	padding-left: 0;
	padding-right: 0;
`;

const TrackProgress = ({ currentTime, duration, onSeek, seeking, setSeeking }: TrackProgressProps) => {
	const [percent, setPercent] = useState(0);
	const [remaining, setRemaining] = useState(duration);

	const seek = (p: number) => {
		const newTime = (p / 100) * duration;
		onSeek(newTime);
	};

	useEffect(() => {
		if (duration >= 0 && currentTime >= 0) {
			if (!seeking) {
				setPercent((currentTime / duration) * 100);
				setRemaining(duration - currentTime);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [duration, currentTime]);

	return (
		<Container>
			<StyledIonRange
				value={percent}
				onIonChange={(e: any) => {
					if (seeking) {
						const percent = e.detail.value;
						setPercent(percent);
						setRemaining(duration * (1 - percent / 100));
						seek(e.detail.value);
					}
				}}
				onPointerUp={() => {
					setSeeking(false);
				}}
				onPointerDown={() => {
					setSeeking(true);
				}}
				mode="ios"
			/>
			<TrackProgressTime>
				<TrackProgressTimeCurrent>{msToTime(currentTime * 1000)}</TrackProgressTimeCurrent>
				<TrackProgressTimeLeft>-{msToTime(remaining * 1000)}</TrackProgressTimeLeft>
			</TrackProgressTime>
		</Container>
	);
};

export default TrackProgress;
