import constate from "constate";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import storage from "../../../common/services/storage";
import { updatePhonemePattern } from "../../../common/utils";
import exercisesAtom from "../../exercises/atoms/exercisesAtom";
import { IExercise } from "../interfaces/exercise";

const useExercise = ({ id, phonemePattern }: any) => {
	const [exercise, setExercise] = useState<IExercise | undefined>(undefined);
	const [xml, setXML] = useState<string | undefined>(undefined);

	const exercises = useAtomValue(exercisesAtom);

	useEffect(() => {
		if (exercises?.length && id) {
			setExercise(exercises.find((ex) => ex.id === id));
		}
	}, [exercises, id]);

	useEffect(() => {
		if (exercise) {
			storage.get(exercise.id).then((xml) => {
				if (xml) {
					setXML(phonemePattern ? updatePhonemePattern(xml, phonemePattern) : xml);
				}
			});
		}
	}, [exercise, phonemePattern]);

	return {
		exercise,
		xml,
		phonemePattern,
	};
};

export const [ExerciseProvider, useExerciseContext] = constate(useExercise);
