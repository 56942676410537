import { IonButton, IonContent, IonInput, IonPage } from "@ionic/react";
import { useState } from "react";
import styled from "styled-components";
import signup from "../actions/signup";

const StyledIonContent = styled(IonContent)`
	--background: #ebebeb;
`;

const Container = styled.div`
	margin: 2rem;
`;

const StyledIonInput = styled(IonInput)`
	border-bottom: 1px solid grey;
	text-align: center;
`;

const Title = styled.div`
	text-align: center;
	font-size: 2rem;
	margin-top: 3rem;
	margin-bottom: 3rem;
`;

const Form = styled.form`
	margin: 2rem;
`;

const SignUpPage = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	return (
		<IonPage>
			<StyledIonContent fullscreen>
				<Container>
					<Title>Cantare</Title>
					<Form>
						<StyledIonInput aria-label="name" value={name} type="text" placeholder="Name" onIonInput={(e) => setName(e.detail.value!)}></StyledIonInput>
						<StyledIonInput aria-label="email" value={email} type="email" placeholder="Email" onIonInput={(e) => setEmail(e.detail.value!)}></StyledIonInput>
						<StyledIonInput aria-label="password" value={password} type="password" placeholder="Password" onIonInput={(e) => setPassword(e.detail.value!)}></StyledIonInput>
					</Form>
					<IonButton
						expand="block"
						onClick={async () => {
							await signup({ name, email, password });
						}}
					>
						Sign Up
					</IonButton>
					<IonButton expand="block" fill="clear" routerLink="/signin">
						Already have an account?
					</IonButton>
				</Container>
			</StyledIonContent>
		</IonPage>
	);
};

export default SignUpPage;
