import { doc, updateDoc } from "@firebase/firestore";
import { auth, db } from "../../../common/services/firebase";
import { IVoiceType } from "../constants/voiceTypes";

const setVoiceType = async (voiceType: IVoiceType) => {
	const user = auth.currentUser;
	if (user) {
		const docRef = doc(db, "users", user.uid);
		await updateDoc(docRef, { voiceType });
	}
	return;
};

export default setVoiceType;
