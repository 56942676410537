import { atom } from "jotai";
import playlistsAtom from "../playlists/state";
import { IPlaylist } from "./../playlists/interfaces";

export const playlistIdAtom = atom<string | undefined>(undefined);

export const playlistAtom = atom<IPlaylist | undefined>((get) => {
	const id = get(playlistIdAtom);
	const playlists = get(playlistsAtom);
	if (id && playlists) {
		const playlist = playlists.find((p) => p.id === id);
		return playlist;
	}
});
