import { AudioContext, AudioWorkletNode, IAudioContext, IMediaStreamAudioSourceNode } from "standardized-audio-context";

class Microphone {
	private audioContext: AudioContext;
	private mic: IMediaStreamAudioSourceNode<IAudioContext> | undefined;
	private nodes: AudioWorkletNode<IAudioContext>[] = [];

	constructor(audioContext: AudioContext) {
		this.audioContext = audioContext;
	}

	private static getUserMediaForAudio(onPermissionGranted: (stream: MediaStream) => void, onPermissionDenied: (message: string) => void) {
		const constraints: MediaStreamConstraints = {
			audio: {
				autoGainControl: false,
				echoCancellation: false,
				noiseSuppression: false,
			},
			video: false,
		};

		let userMedia = null;
		let userMediaError = false;

		try {
			userMedia = navigator.mediaDevices.getUserMedia;
		} catch (error: any) {
			if (window.location.protocol.toLowerCase() !== "https" && window.location.hostname.toLowerCase() !== "localhost")
				onPermissionDenied("Web Audio requires a secure context (HTTPS or localhost).");
			else onPermissionDenied(error);
			userMediaError = true;
		}

		if (!userMediaError) {
			if (userMedia) navigator.mediaDevices.getUserMedia(constraints).then(onPermissionGranted).catch(onPermissionDenied);
			else onPermissionDenied("Can't access getUserMedia.");
		}
	}

	public static async getUserMediaForAudioAsync(): Promise<MediaStream> {
		return new Promise((resolve, reject) => {
			Microphone.getUserMediaForAudio((stream: MediaStream) => {
				let audioTracks = stream.getAudioTracks();
				for (let audioTrack of audioTracks) audioTrack.applyConstraints({ autoGainControl: false, echoCancellation: false, noiseSuppression: false });
				resolve(stream);
			}, reject);
		});
	}

	public async createAudioNode(processorName: string, onMessageFromAudioScope?: (...args: any[]) => void, options?: AudioWorkletNodeOptions): Promise<AudioWorkletNode<IAudioContext>> {
		return new Promise(async (resolve, reject) => {
			try {
				const workletUrl = await import(`../../worklets/${processorName}.worklet.ts`).catch(function moduleLoadRejected(msg) {
					throw new Error(`There was a problem loading the AudioWorklet module code: \n ${msg}`);
				});

				await this.audioContext.audioWorklet?.addModule(workletUrl.default).catch(function moduleLoadRejected(msg) {
					throw new Error(`There was a problem loading the AudioWorklet module code: \n ${msg}`);
				});

				const node = new AudioWorkletNode!(this.audioContext, processorName, options);
				if (onMessageFromAudioScope) {
					node.port.onmessage = function (event) {
						onMessageFromAudioScope(event.data);
					};
				}
				resolve(node);
			} catch (e) {
				reject(e);
			}
		});
	}

	public async setup() {
		try {
			const stream = await Microphone.getUserMediaForAudioAsync();
			this.mic = this.audioContext.createMediaStreamSource(stream);
			this.mic.connect(this.audioContext.destination);
			return await this.audioContext.suspend();
		} catch (e) {
			throw new Error();
		}
	}

	public async addNode(processorName: string, onData: (data: any) => void, options: AudioWorkletNodeOptions) {
		if (this.mic === undefined) return;
		try {
			this.mic.disconnect();

			this.nodes.forEach((node) => node?.disconnect());
			const node = await this.createAudioNode(processorName, onData, options);
			this.mic.connect(node!);
			node?.connect(this.audioContext.destination);
		} catch (e) {
			throw new Error();
		}
	}

	public async start() {
		if (this.mic === undefined) return;
		return await this.audioContext.resume();
	}

	public async stop() {
		if (this.mic === undefined) return;
		return await this.audioContext.suspend();
	}

	public async destroy() {
		if (this.mic === undefined) return;
		this.mic.disconnect();
		this.nodes.forEach((node) => node?.disconnect());
		this.mic = undefined;
		this.nodes = [];
		this.audioContext.close();
	}

	public getNodes() {
		return this.nodes;
	}
}

export default Microphone;
