import { ref, uploadBytesResumable } from "firebase/storage";
import { firebaseStorage } from "../../../common/services/firebase";

export const useUpload = () => {
	const upload = async (directory: string, filename: string, blob: Blob, onProgress: (percent: number) => void) => {
		const sessionsRef = ref(firebaseStorage, `sessions/${directory}/${filename}`);
		const uploadTask = uploadBytesResumable(sessionsRef, blob);

		return new Promise<void>(async (resolve, reject) => {
			uploadTask.on(
				"state_changed",
				(snapshot) => {
					// Observe state change events such as progress, pause, and resume
					// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
					const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
					switch (snapshot.state) {
						case "paused":
							break;
						case "running":
							break;
					}
					onProgress(percent);
				},
				(error) => {
					reject(error);
				},
				() => {
					resolve();
				}
			);

			await uploadTask;
		});
	};

	return {
		upload,
	};
};
