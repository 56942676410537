import { ref, uploadBytesResumable } from "firebase/storage";
import { firebaseStorage } from "../../../common/services/firebase";

interface IUploadXMLParams {
	blob: Blob;
	filename: string;
	onProgress: (progress: number) => void;
}

const uploadXML = async ({ blob, filename, onProgress }: IUploadXMLParams) => {
	return new Promise<void>(async (resolve, reject) => {
		const storageRef = ref(firebaseStorage, `xml/${filename}`);
		const uploadTask = uploadBytesResumable(storageRef, blob);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				// Observe state change events such as progress, pause, and resume
				// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
				const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
				onProgress(progress);

				switch (snapshot.state) {
					case "paused":
						break;
					case "running":
						break;
				}
			},
			(_error) => {
				// Handle unsuccessful uploads
				reject();
			},
			() => {
				resolve();
			}
		);
	});
};

export default uploadXML;
