import { IonButton, IonButtons, IonIcon, IonSpinner, useIonAlert } from "@ionic/react";
import { pause as pauseIcon, play as playIcon, stop as stopIcon } from "ionicons/icons";
import { useSetAtom } from "jotai";
import { Suspense, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Recorder from "../../../common/classes/Recorder";
import { usePrevious } from "../../../common/hooks/usePrevious";
import { toastAtom } from "../../../common/state";
import { midiToNote } from "../../../common/utils";
import { createSession } from "../../sessions/actions/createSession";
import { useUpload } from "../../sessions/hooks/useUpload";
import { useExerciseContext } from "../constates/exercise";
import { useExerciseSettingsContext } from "../constates/exerciseSettings";
import { useOSMDContext } from "../constates/osmd";
import { AudioPlayerState, usePlaybackContext } from "../constates/playback";

const StyledIonButtons = styled(IonButtons)`
	height: 100%;
	justify-content: space-around;
	background-color: rgb(34, 34, 34);

	ion-icon {
		fill: grey;
	}
`;

const AudioPlayerControls = () => {
	const { transposition, isLastRep, maxTransposition, iteration } = useOSMDContext();
	const [present] = useIonAlert();
	const { play, stop, pause, audioState } = usePlaybackContext();
	const { upload } = useUpload();
	const { exercise, phonemePattern } = useExerciseContext();
	const { exerciseSettings } = useExerciseSettingsContext();
	const recorderRef = useRef<Recorder>();
	const metadatasRef = useRef<any>([]);
	const previousAudioState = usePrevious(audioState);
	const [willClear, setWillClear] = useState(false);

	const setToast = useSetAtom(toastAtom);

	const saveSession = async (blobs: Blob[]) => {
		if (!exercise) return;
		const session = await createSession(exerciseSettings, exercise?.name, phonemePattern);
		if (session) {
			for (let index = 0; index < blobs.length; index++) {
				const blob = blobs[index];
				const { lowNote, highNote, time } = metadatasRef.current[index];

				const filename = [time, exerciseSettings.tempo, lowNote, highNote, phonemePattern].filter(Boolean).join("_") + ".mp3";

				try {
					setToast(undefined);
					await upload(session.id, filename, blob, (percent: number) => {
						setToast(`Uploading ${index + 1}/${blobs.length}...`);
					});
				} catch (e) {}
			}
			setToast(undefined);
			setToast("Upload complete!");
			setTimeout(() => {
				setToast(undefined);
			}, 1000);
		} else {
			throw new Error();
		}

		metadatasRef.current = [];
	};

	const showSaveSessionPrompt = useCallback(
		async (blobs: any[]) => {
			return new Promise<void>((resolve, reject) => {
				present({
					header: "Do you want to save this session?",
					buttons: [
						{
							text: "No",
							handler: (_d) => {
								reject();
							},
						},
						{
							text: "Yes",
							handler: async (_d) => {
								await saveSession(blobs);
								resolve();
							},
						},
					],
					onDidDismiss: (e) => {},
					backdropDismiss: false,
				});
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[present]
	);

	useEffect(() => {
		if (maxTransposition > 0) {
			recorderRef.current = new Recorder(2 * maxTransposition + 1, (blobs) => {
				showSaveSessionPrompt(blobs).finally(() => {
					recorderRef.current?.clearAudioBlobs();
				});
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [maxTransposition]);

	useEffect(() => {
		if (audioState === AudioPlayerState.LOADING) return;

		const processIteration = async () => {
			// Don't trigger recorder stop function if just starting
			if (iteration !== 0 || isLastRep) {
				await recorderRef.current?.stop().catch(() => {});
			}
			const metadata = {
				time: Date.now(),
				lowNote: midiToNote(exerciseSettings.range.lowNoteMidi + transposition),
				highNote: midiToNote(exerciseSettings.range.highNoteMidi + transposition),
			};

			metadatasRef.current.push(metadata);

			if (!isLastRep) {
				await recorderRef.current?.start();
			}
		};

		processIteration();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [iteration]);

	useEffect(() => {
		switch (audioState) {
			case AudioPlayerState.STOPPED:
				recorderRef.current?.stop(willClear).finally(() => {
					setWillClear(false);
				});
				break;
			case AudioPlayerState.PAUSED:
				recorderRef.current?.pause();
				break;
			case AudioPlayerState.RUNNING:
				if (previousAudioState === AudioPlayerState.STOPPED) {
					recorderRef.current?.start();
				} else if (previousAudioState === AudioPlayerState.PAUSED) {
					recorderRef.current?.resume();
				}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [audioState]);

	return (
		<Suspense fallback={<IonSpinner />}>
			<StyledIonButtons>
				{(audioState === AudioPlayerState.STOPPED || audioState === AudioPlayerState.PAUSED) && (
					<IonButton
						onClick={async () => {
							await recorderRef.current?.setupMicrophone();
							await play();
						}}
					>
						<IonIcon slot="icon-only" icon={playIcon} />
					</IonButton>
				)}
				{audioState === AudioPlayerState.RUNNING && (
					<>
						<IonButton
							onClick={async () => {
								await pause();
							}}
						>
							<IonIcon slot="icon-only" icon={pauseIcon} />
						</IonButton>
						<IonButton
							onClick={async () => {
								setWillClear(true);
								await stop();
							}}
						>
							<IonIcon slot="icon-only" icon={stopIcon} />
						</IonButton>
					</>
				)}
				{audioState === AudioPlayerState.LOADING && <IonSpinner />}
			</StyledIonButtons>
		</Suspense>
	);
};

export default AudioPlayerControls;
