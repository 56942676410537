import React, { useRef } from "react";
import styled from "styled-components";
import { RenderProvider } from "../constates/render";
interface OpenSheetMusicDisplayProps {
	xml: string;
}

const ExerciseContainer = styled.div`
	margin-top: 1.5rem;
`;

const NextText = styled.div`
	font-size: 1.5rem;
	color: black;
	text-align: center;
	padding-bottom: 0.5rem;
`;

const SheetMusic = ({ xml }: OpenSheetMusicDisplayProps) => {
	const osmdRef = useRef<any>();
	const osmdPreviewRef = useRef<any>();
	const nextTextRef = useRef<any>();

	return (
		<RenderProvider xml={xml} osmdRef={osmdRef} osmdPreviewRef={osmdPreviewRef} nextTextRef={nextTextRef}>
			<ExerciseContainer>
				<div ref={osmdRef} />
				<NextText ref={nextTextRef}>Next...</NextText>
				<div ref={osmdPreviewRef} />
			</ExerciseContainer>
		</RenderProvider>
	);
};

export default SheetMusic;
