import { IonSpinner } from "@ionic/react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import useAudio from "../hooks/useAudio";
import PlaybackButton from "./PlaybackButton";
import TrackProgress from "./TrackProgress";

const StyledIonSpinner = styled(IonSpinner)`
	color: black;
	margin: 1rem 0;
	justify-self: center;
	width: 100%;
`;

const Container = styled.div`
	display: flex;
	flex-direction: row;
	margin: 0.5rem 0;
`;

interface TrackPlayerProps {
	audioUrl: string;
	autoplay?: boolean;
}

const TrackPlayer = ({ audioUrl, autoplay }: TrackPlayerProps) => {
	const [seeking, setSeeking] = useState(false);
	const { togglePlayback, currentTime, duration, seek, playing, loading } = useAudio({ audioUrl, seeking });

	useEffect(() => {
		if (togglePlayback && autoplay) {
			togglePlayback();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [autoplay]);

	return (
		<>
			{loading ? (
				<StyledIonSpinner />
			) : (
				<Container>
					<TrackProgress currentTime={currentTime} duration={duration} onSeek={seek} seeking={seeking} setSeeking={setSeeking} />
					<PlaybackButton playing={playing} onClick={togglePlayback} />
				</Container>
			)}
		</>
	);
};

export default TrackPlayer;
