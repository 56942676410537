import { deleteObject, ref } from "@firebase/storage";
import { deleteDoc, doc } from "firebase/firestore";
import { auth, db, firebaseStorage } from "../../../common/services/firebase";
import storage from "../../../common/services/storage";
import { IExercise } from "./../../exercise/interfaces/exercise";

export const deleteExercise = async (exercise: IExercise): Promise<void> => {
	const user = auth.currentUser;
	if (user && user.uid === exercise.createdBy) {
		await deleteDoc(doc(db, "exercises", exercise.id));
		const fileRef = ref(firebaseStorage, `xml/${exercise.filename}`);
		await deleteObject(fileRef);
		await storage.remove(exercise.id);
	}
};
