import { useIonModal, useIonToast } from "@ionic/react";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import storage from "../../../common/services/storage";
import { getElementCountByTag, getValueByTag } from "../../../common/utils";
import { IExercise } from "../../exercise/interfaces/exercise";
import uploadExercise from "../actions/uploadExercise";
import uploadXML from "../actions/uploadXML";
import downloadedExerciseIdsAtom from "../atoms/downloadedExercisesAtom";
import ExerciseUploadScreen from "../components/ExerciseUploadScreen";
import { IExerciseUpload } from "../interfaces";

const useExerciseUpload = () => {
	const [filesToBeUploaded, setFilesToBeUploaded] = useState<File[]>([]);
	const [exerciseUploadDetails, setExerciseUploadDetails] = useState<IExerciseUpload>();
	const [percentUploadedByExercise, setPercentUploadedByExercise] = useState<{
		[key: string]: number;
	}>({});
	const [xml, setXML] = useState<string>();
	const [exerciseUploads, setExerciseUploads] = useState<IExercise[]>([]);
	const [downloadedExerciseIds, setDownloadedExerciseIds] = useAtom(downloadedExerciseIdsAtom);
	const [presentToast] = useIonToast();

	const setProgress = (exerciseId: string, progress: number) => {
		setPercentUploadedByExercise({
			...percentUploadedByExercise,
			[exerciseId]: progress,
		});
	};

	const handleDismiss = () => {
		dismiss();
	};

	const [present, dismiss] = useIonModal(ExerciseUploadScreen, {
		onDismiss: handleDismiss,
		onSave: async (data: IExerciseUpload) => {
			handleDismiss();
			if (data && xml) {
				const exerciseId = await uploadExercise({
					metadata: data,
				});

				if (exerciseId) {
					setProgress(exerciseId, 0);
					setExerciseUploads([...exerciseUploads, { ...data, id: exerciseId }]);

					try {
						await uploadXML({
							blob: filesToBeUploaded[0],
							filename: data.filename,
							onProgress: (progress: number) => {
								setProgress(exerciseId, progress);
							},
						});
						storage.set(exerciseId, xml);
						setDownloadedExerciseIds([...downloadedExerciseIds, exerciseId]);
						setExerciseUploads(exerciseUploads.filter((e) => e.id === exerciseId));

						const state = { ...percentUploadedByExercise };
						delete state[exerciseId];
						setPercentUploadedByExercise(state);

						setXML(undefined);
						setFilesToBeUploaded([]);
					} catch (e) {}
				}
			}
		},
		...exerciseUploadDetails,
	});

	useEffect(() => {
		filesToBeUploaded.forEach((file) => {
			var reader = new FileReader();
			reader.readAsText(file);
			reader.onloadend = function () {
				try {
					const xml = reader.result as string;
					const measureCount = getElementCountByTag(xml, "measure");
					if (measureCount > 8) {
						presentToast({
							color: "danger",
							message: "Must be at most 8 measures long!",
							duration: 2000,
							position: "top",
						});
					} else {
						const createdAt = new Date();
						setExerciseUploadDetails({
							baseTempo: Number(getValueByTag(xml, "per-minute") || 90),
							name: file.name.split(".")[0] || getValueByTag(xml, "work-title"),
							filename: file.name + "_" + createdAt.getTime(),
							createdAt,
						});
						setXML(xml);
						present();
					}
				} catch (e) {
					console.error("Invalid XML");
				}
			};
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filesToBeUploaded]);

	return { setFilesToBeUploaded, percentUploadedByExercise, exerciseUploads };
};

export default useExerciseUpload;
