import { useAtom } from "jotai";
import { useState } from "react";
import storage from "../../../common/services/storage";
import { IExercise } from "../../exercise/interfaces/exercise";
import getXML from "../actions/getXML";
import downloadedExerciseIdsAtom from "../atoms/downloadedExercisesAtom";

const useExerciseDownload = () => {
	const [downloadedExerciseIds, setDownloadedExerciseIds] = useAtom(downloadedExerciseIdsAtom);
	const [percentDownloadedByExercise, setPercentDownloadedByExercise] = useState<{
		[key: string]: number;
	}>({});

	const download = async (exercise: IExercise) => {
		setPercentDownloadedByExercise({
			...percentDownloadedByExercise,
			[exercise.id]: 0,
		});
		const xml = await getXML(exercise.filename, (progress) => {
			setPercentDownloadedByExercise({
				...percentDownloadedByExercise,
				[exercise.id]: progress,
			});
		});
		if (xml) {
			storage.set(exercise.id, xml);
			setDownloadedExerciseIds([...downloadedExerciseIds, exercise.id]);
		}
	};

	return {
		downloadedExerciseIds,
		percentDownloadedByExercise,
		download,
	};
};

export default useExerciseDownload;
