import { IonButton } from "@ionic/react";
import styled from "styled-components";

const StyledContainer = styled.div`
	height: 70vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

const Description = styled.div`
	font-size: 1.2rem;
	margin-bottom: 1rem;
`;

interface Props {
	description: string;
	action?: () => void;
	actionText?: string;
	icon?: string;
}

const EmptyState = ({ action, actionText, description, icon }: Props) => (
	<StyledContainer>
		<Description>{description}</Description>
		{action && actionText && (
			<IonButton expand="block" onClick={action}>
				{actionText}
			</IonButton>
		)}
	</StyledContainer>
);

export default EmptyState;
