import { IonApp, IonLoading, IonRouterOutlet, IonToast, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import { useAtomValue } from "jotai";
import { Redirect, Route } from "react-router-dom";
import styled from "styled-components";
import HomePage from "./common/pages/HomePage";
import { toastAtom } from "./common/state";
import ExercisePage from "./features/exercise/pages/ExercisePage";
import PlaylistPage from "./features/playlist/PlaylistPage";
import SignInPage from "./features/user/pages/SignInPage";
import SignUpPage from "./features/user/pages/SignUpPage";
import userAtom from "./features/user/state";
/* Theme variables */
import "./theme/variables.css";

const PublicRoutes = () => {
	return (
		<IonRouterOutlet>
			<Route path="/signin" render={() => <SignInPage />} exact={true} />
			<Route path="/signup" render={() => <SignUpPage />} exact={true} />
			<Redirect exact from="/home/profile" to="/" />
			<Redirect exact from="/" to="/signin" />
		</IonRouterOutlet>
	);
};

const PrivateRoutes = () => {
	return (
		<IonRouterOutlet>
			<Route path="/home" render={() => <HomePage />} />
			<Route path="/exercise/:id/:phonemePattern?" exact component={ExercisePage} />
			<Route path="/routine/:id" exact component={PlaylistPage} />
			<Redirect exact from="/" to="/home/exercises" />
			<Redirect exact from="/signin" to="/home/exercises" />
		</IonRouterOutlet>
	);
};

const StyledToast = styled(IonToast)`
	transform: translateY(-50px);
`;

const App: React.FC = () => {
	setupIonicReact({
		rippleEffect: false,
		mode: "ios",
	});

	const user = useAtomValue(userAtom);
	const toast = useAtomValue(toastAtom);

	return (
		<IonApp>
			<IonLoading message="Loading..." isOpen={user === undefined} />
			<IonReactRouter>{user !== undefined && (user ? <PrivateRoutes /> : <PublicRoutes />)}</IonReactRouter>
			{!!toast && <StyledToast isOpen={true} position="bottom" message={toast} />}
		</IonApp>
	);
};

export default App;
