import { IonIcon } from "@ionic/react";
import { pause, play } from "ionicons/icons";
import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
	color: black;
	font-size: 30px;
	flex: 0.1;
	align-self: center;

	transition: 0.2s;
	border: 1px solid black;
	display: flex;
	border-radius: 2rem;
	cursor: pointer;
	padding: 0.4rem;
	justify-content: center;

	&:hover {
		background: gray;
	}
`;

interface Props {
	playing: boolean;
	onClick: () => void;
}

const PlaybackButton = ({ playing, onClick }: Props) => {
	return (
		<Container onClick={onClick}>
			<IonIcon className="play-pause" icon={playing ? pause : play} />
		</Container>
	);
};

export default PlaybackButton;
