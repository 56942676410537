import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonSpinner, IonTextarea, IonTitle, IonToolbar } from "@ionic/react";
import { checkmark, close, play, thumbsDown, thumbsUp } from "ionicons/icons";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { unvote } from "../../sessions/actions/unvote";
import { vote } from "../../sessions/actions/vote";
import { ISession } from "../../sessions/interfaces";
import { userIdAtom } from "../../user/state";
import getSession from "../actions/getSession";
import getSessionAudio from "../actions/getSessionAudio";
import getSessionFiles, { ISessionFile } from "../actions/getSessionFiles";
import setSessionNote from "../actions/setSessionNote";
import TrackPlayer from "../components/TrackPlayer";
import votesAtom from "../state";

const StyledTextarea = styled(IonTextarea)`
	padding: 1rem;
	line-height: 1.5;

	.textarea-wrapper,
	textarea {
		height: 100%;
	}
`;

const StyledIonFooter = styled(IonFooter)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 1rem;
`;

const StyledIonSpinner = styled(IonSpinner)`
	color: black;
	margin: 1rem 0;
	justify-self: center;
	width: 100%;
`;

interface Props extends ISession {
	onDismiss: () => void;
}

const SessionPage = ({ id, name, onDismiss }: Props) => {
	const [audioUrl, setAudioUrl] = useState<string | null>(null);
	const [note, setNote] = useState<string>("");
	const [draftNote, setDraftNote] = useState<string>("");
	const userId = useAtomValue(userIdAtom);
	const [sessionFiles, setSessionFiles] = useState<ISessionFile[]>([]);
	const [selectedFile, setSelectedFile] = useState<string | null>(null);
	// const [votes, setVotes] = useState<IVotes>({});
	const [votes, setSessionId] = useAtom(votesAtom);

	const saveNote = () => {
		if (draftNote && draftNote !== note) {
			setSessionNote(id, draftNote);
		}
		onDismiss();
	};

	useEffect(() => {
		if (id && userId) {
			getSession(id).then((session) => {
				setNote(session?.note || "");
				setDraftNote(session?.note || "");
			});

			getSessionFiles(id).then(setSessionFiles);
			if (id) {
				setSessionId(id);
			}
		}
	}, [id, setSessionId, userId]);

	const selectFile = async (filename: string) => {
		setAudioUrl(null);
		try {
			const audioUrl = await getSessionAudio(id, filename);
			setAudioUrl(audioUrl);
			setSelectedFile(filename);
		} catch (e) {}
	};

	const upvote = async (filename: string) => {
		await vote(id, filename);
	};

	const downvote = async (filename: string) => {
		await vote(id, filename, true);
	};

	return (
		<>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonButton fill="clear" onClick={onDismiss}>
							<IonIcon icon={close} />
						</IonButton>
					</IonButtons>
					<IonTitle>{name}</IonTitle>
					{note !== draftNote && (
						<IonButtons slot="end">
							<IonButton fill="clear" onClick={saveNote}>
								<IonIcon icon={checkmark} />
							</IonButton>
						</IonButtons>
					)}
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{sessionFiles
					.sort((a, b) => b.timestamp - a.timestamp)
					.map(({ lowNote, highNote, filename }, idx) => (
						<IonItem
							detail={false}
							button
							key={idx}
							color={selectedFile === filename ? "primary" : undefined}
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								selectFile(filename);
							}}
						>
							<IonLabel>
								<h2>
									{lowNote}-{highNote}
								</h2>
							</IonLabel>
							<IonIcon
								icon={thumbsUp}
								slot="end"
								color={votes[filename] === 1 ? "success" : "medium"}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									if (votes[filename] === 1) {
										unvote(id, filename);
									} else {
										upvote(filename);
									}
								}}
							/>
							<IonIcon
								icon={thumbsDown}
								slot="end"
								color={votes[filename] === -1 ? "danger" : "medium"}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									if (votes[filename] === -1) {
										unvote(id, filename);
									} else {
										downvote(filename);
									}
								}}
							/>
							<IonIcon icon={play} slot="end" />
						</IonItem>
					))}
				<StyledTextarea value={draftNote} onIonInput={(e) => setDraftNote(e.detail.value!)} placeholder="Add notes" />
			</IonContent>
			{selectedFile && <StyledIonFooter>{audioUrl ? <TrackPlayer audioUrl={audioUrl} autoplay={true} /> : <StyledIonSpinner />}</StyledIonFooter>}
		</>
	);
};

export default SessionPage;
