import { collection, onSnapshot, query } from "firebase/firestore";
import { atom } from "jotai";
import { auth, db } from "../../../common/services/firebase";
import { IHistory } from "../interfaces";

const historyAtom = atom<IHistory | undefined>(undefined);

historyAtom.onMount = (setAtom) => {
	const user = auth.currentUser;

	if (user) {
		const q = query(collection(db, `users/${user.uid}/history`));
		onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
			const history: IHistory = {};
			snapshot.docs.forEach((doc) => {
				const data = doc.data();
				history[doc.id] = {
					exerciseId: data.id,
					updatedAt: data.updatedAt.toDate(),
					tempo: data.tempo,
					range: data.range,
					transposition: data.transposition,
					vocalPlaybackDisabled: data.vocalPlaybackDisabled
				};
			});
			setAtom(history);
		});
	}
};

export default historyAtom;
