import { collection, onSnapshot, query, Unsubscribe } from "firebase/firestore";
import { atom } from "jotai";
import { db } from "../../../common/services/firebase";
import { IExercise } from "../../exercise/interfaces/exercise";
import historyAtom from "./historyAtom";

const INIT = Symbol(); // or anything that doesn't conflict with data.
const DESTROY = Symbol();

// const exercisesAtom = atom<IExercise[]>([]);

// exercisesAtom.onMount = (setAtom) => {
// 	const q = query(collection(db, "exercises"));
// 	onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
// 		const newExercises = snapshot.docs.map((doc) => ({ ...(doc.data() as IExercise), id: doc.id }));
// 		setAtom(newExercises);
// 	});
// };

const defaultExercisesAtom = atom<IExercise[]>([]);
const subscriptionAtom = atom<Unsubscribe | undefined>(undefined);

const exercisesAtom = atom(
	(get) => {
		const exercises = get(defaultExercisesAtom);

		const history = get(historyAtom);
		if (history) {
			return exercises.sort((a, b) => {
				if (b.id in history && a.id in history) {
					return history[b.id].updatedAt.getTime() - history[a.id].updatedAt.getTime();
				} else if (b.id in history) {
					return 1;
				} else if (a.id in history) {
					return -1;
				} else {
					return b.createdAt.getTime() - a.createdAt.getTime();
				}
			});
		}
		return exercises;
	},
	(get, set, arg) => {
		if (arg === INIT) {
			// const userId = get(userAtom)?.id;
			// const q = query(collection(db, `users/${userId}/sessions`));

			// const unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
			// 	const newSessions = snapshot.docs.map((doc) => ({ ...(doc.data() as ISession), id: doc.id }));
			// 	set(defaultSessionsAtom, newSessions);
			// });

			const q = query(collection(db, "exercises"));
			onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
				const newExercises = snapshot.docs.map((doc) => ({ ...(doc.data() as IExercise), id: doc.id, createdAt: doc.data().createdAt.toDate() }));
				set(defaultExercisesAtom, newExercises);
			});
		} else if (arg === DESTROY) {
			const unsubscribe = get(subscriptionAtom);
			if (unsubscribe) {
				unsubscribe();
			}
		} else {
			set(defaultExercisesAtom, arg as IExercise[]);
		}
	}
);

exercisesAtom.onMount = (dispatch) => {
	dispatch(INIT);
};

export default exercisesAtom;
