import constate from "constate";
import { OpenSheetMusicDisplay } from "osmd-extended";
import { useEffect, useState } from "react";
import { useExerciseSettingsContext } from "./exerciseSettings";

const useOSMD = () => {
	const [osmd, setOsmd] = useState<OpenSheetMusicDisplay | undefined>(undefined);
	const [osmdPreview, setOsmdPreview] = useState<OpenSheetMusicDisplay | undefined>(undefined);
	const [iteration, setIteration] = useState<number>(0);
	const [currentNote, setCurrentNote] = useState<number>(0);
	const [reverse, setReverse] = useState(false);
	const [transposition, setTransposition] = useState(0);
	const [isLastRep, setIsLastRep] = useState(false);
	const [willBeLastRep, setWillBeLastRep] = useState(false);

	const { exerciseRange, exerciseOffset, range, tempo } = useExerciseSettingsContext();

	const [maxTransposition, setMaxTransposition] = useState(0);

	useEffect(() => {
		const rangeSpan = range.highNoteMidi - range.lowNoteMidi;
		const exerciseSpan = exerciseRange.highNoteMidi - exerciseRange.lowNoteMidi;
		setMaxTransposition(rangeSpan - exerciseSpan);
	}, [exerciseRange.highNoteMidi, exerciseRange.lowNoteMidi, range.highNoteMidi, range.lowNoteMidi]);

	const transpose = (osmd: OpenSheetMusicDisplay, amount: number): void => {
		osmd.Sheet.Transpose = amount;
		osmd.updateGraphic();
		setTimeout(() => {
			if (osmd.IsReadyToRender()) {
				osmd.render();
			} else {
				console.log("[OSMD demo] Loses context!"); // TODO not sure that this message is reasonable, renders fine anyways. maybe vexflow context lost?
			}
		}, 0);
	};

	useEffect(() => {
		if (osmd) {
			const amount = transposition + (exerciseOffset ?? 0);
			transpose(osmd, amount);
			if (osmdPreview) {
				const animationRate = 1000 * (60 / tempo);
				setTimeout(() => {
					transpose(osmdPreview, osmd.Sheet.Transpose + (transposition >= maxTransposition || reverse ? -1 : 1));
				}, animationRate);
			}
			if (transposition >= maxTransposition) {
				setReverse(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transposition]);

	useEffect(() => {
		if (osmd && exerciseOffset !== undefined) {
			transpose(osmd, exerciseOffset);
			if (osmdPreview) {
				transpose(osmdPreview, exerciseOffset + 1);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [osmd, osmdPreview, exerciseOffset]);

	useEffect(() => {
		if (reverse) {
			if (transposition === 0) {
				setIsLastRep(true);
			} else if (transposition === 1) {
				setWillBeLastRep(true);
			}
		}
	}, [reverse, transposition]);

	const reset = () => {
		setReverse(false);
		setTransposition(0);
		setIsLastRep(false);
		setWillBeLastRep(false);
	};

	return {
		osmd,
		setOsmd,
		osmdPreview,
		setOsmdPreview,
		iteration,
		setIteration,
		currentNote,
		setCurrentNote,
		reverse,
		setReverse,
		transpose,
		transposition,
		setTransposition,
		maxTransposition,
		isLastRep,
		willBeLastRep,
		reset,
	};
};

export const [OSMDProvider, useOSMDContext] = constate(useOSMD);
