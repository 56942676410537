import { doc, getDoc } from "@firebase/firestore";
import { auth, db } from "../../../common/services/firebase";
import { ISession } from "./../../sessions/interfaces";

const getSession = async (sessionId: string): Promise<ISession | undefined> => {
	const user = auth.currentUser;
	if (user) {
		const docRef = doc(db, "users", user.uid, "sessions", sessionId);
		const docSnap = await getDoc(docRef);
		return docSnap.data() as ISession;
	}
};

export default getSession;
