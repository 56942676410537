import { IonButton, IonContent, IonInput, IonLoading, IonPage } from "@ionic/react";
import { useState } from "react";
import styled from "styled-components";
import signin from "../actions/signin";

const StyledIonContent = styled(IonContent)`
	--background: #ebebeb;
`;

const Container = styled.div`
	margin: 2rem;
`;

const StyledIonInput = styled(IonInput)`
	border-bottom: 1px solid grey;
	text-align: center;
`;

const Title = styled.div`
	text-align: center;
	font-size: 2rem;
	margin-top: 3rem;
	margin-bottom: 3rem;
`;

const Form = styled.form`
	margin: 2rem;
`;

const SignInPage = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);

	return (
		<IonPage>
			<StyledIonContent fullscreen>
				<Container>
					<Title>Cantare</Title>
					<Form>
						<StyledIonInput aria-label="email" value={email} type="email" placeholder="Email" onIonInput={(e) => setEmail(e.detail.value!)}></StyledIonInput>
						<StyledIonInput
							aria-label="password"
							value={password}
							type="password"
							placeholder="Password"
							onIonInput={(e) => {
								setPassword(e.detail.value!);
							}}
						></StyledIonInput>
					</Form>
					<IonButton
						expand="block"
						onClick={async () => {
							setLoading(true);
							await signin({
								email,
								password,
							});
							setLoading(false);
						}}
					>
						Sign in
					</IonButton>
					<IonButton expand="block" fill="clear" routerLink="/signup">
						Create an account
					</IonButton>
				</Container>
			</StyledIonContent>
			<IonLoading message="Signing in..." isOpen={loading} />
		</IonPage>
	);
};

export default SignInPage;
