import { listAll, ref } from "firebase/storage";
import { firebaseStorage } from "../../../common/services/firebase";

export interface ISessionFile {
	filename: string;
	timestamp: number;
	// phonemePattern: string;
	lowNote: string;
	highNote: string;
	// tempo: number;
}

const getSessionFiles = async (id: string): Promise<ISessionFile[]> => {
	const listRef = ref(firebaseStorage, `sessions/${id}`);
	const res = await listAll(listRef);
	return res.items.map((item) => {
		const filename = item.name;
		const [timestamp, , lowNote, highNote] = filename.replace(".mp3", "").split("_");
		return {
			filename,
			timestamp: parseInt(timestamp),
			// phonemePattern,
			lowNote,
			highNote,
			// tempo: parseInt(tempo),
		};
	});
};

export default getSessionFiles;
