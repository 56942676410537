import { collection, onSnapshot, query, Unsubscribe, where } from "firebase/firestore";
import { atom } from "jotai";
import { db } from "../../common/services/firebase";
import userAtom from "../user/state";
import { ISession } from "./interfaces";

const INIT = Symbol(); // or anything that doesn't conflict with data.
const DESTROY = Symbol();

// playlistsAtom.onMount = (setAtom) => {
// 	const q = query(collection(db, "playlists"));
// 	onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
// 		const newExercises = snapshot.docs.map((doc) => ({ ...(doc.data() as IExercise), id: doc.id }));
// 		setAtom(newExercises);
// 	});
// };

const defaultTodaySessionsAtom = atom<ISession[]>([]);

export const todaySessionsAtom = atom(
	(get) => get(defaultTodaySessionsAtom),
	(get, set, arg) => {
		let unsubscribe: Unsubscribe | undefined;

		if (arg === INIT) {
			const userId = get(userAtom)?.id;
			if (userId) {
				const start = new Date();
				start.setHours(0, 0, 0, 0);
				const end = new Date();
				end.setDate(end.getDate() + 1);
				start.setHours(0, 0, 0, 0);

				const q = query(collection(db, "users", userId, "sessions"), where("createdAt", ">=", start.getTime()), where("createdAt", "<", end.getTime()));

				unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
					const todaySessions = snapshot.docs.map((doc) => ({ ...(doc.data() as ISession), id: doc.id }));
					set(defaultTodaySessionsAtom, todaySessions);
				});
			}
		} else if (arg === DESTROY) {
			if (unsubscribe) {
				unsubscribe();
			}
		} else {
			set(defaultTodaySessionsAtom, arg as ISession[]);
		}
	}
);

todaySessionsAtom.onMount = (dispatch) => {
	dispatch(INIT);

	return () => {
		dispatch(DESTROY);
	};
};

const defaultSessionsAtom = atom<ISession[]>([]);

const sessionsAtom = atom(
	(get) => get(defaultSessionsAtom),
	(get, set, arg) => {
		let unsubscribe: Unsubscribe | undefined;
		if (arg === INIT) {
			const userId = get(userAtom)?.id;
			const q = query(collection(db, `users/${userId}/sessions`));

			unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
				const newSessions = snapshot.docs.map((doc) => ({ ...(doc.data() as ISession), id: doc.id }));
				set(defaultSessionsAtom, newSessions);
			});
		} else if (arg === DESTROY) {
			if (unsubscribe) {
				unsubscribe();
			}
		} else {
			set(defaultSessionsAtom, arg as ISession[]);
		}
	}
);

sessionsAtom.onMount = (dispatch) => {
	dispatch(INIT);

	return () => {
		dispatch(DESTROY);
	};
};

export default sessionsAtom;
