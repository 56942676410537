import { updateProfile } from "@firebase/auth";
import { auth } from "../../../common/services/firebase";
import { User } from "./../interfaces";

const setUser = async ({ email, name }: Partial<User>) => {
	const user = auth.currentUser;

	if (user) {
		await updateProfile(user, JSON.parse(JSON.stringify({ email, displayName: name })));
	}
	return null;
};

export default setUser;
