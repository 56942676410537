import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonPage, IonSpinner, IonTitle, IonToolbar } from "@ionic/react";
import { settingsOutline } from "ionicons/icons";
import { Suspense, useState } from "react";
import { useParams } from "react-router";
import LoadingScreen from "../../../common/components/LoadingScreen";
import StyledIonFooter from "../../../common/components/StyledIonFooter";
import AudioPlayerControls from "../components/AudioPlayerControls";
import ExerciseSettingsScreen from "../components/ExerciseSettingsScreen";
import SheetMusic from "../components/SheetMusic";
import { ExerciseProvider, useExerciseContext } from "../constates/exercise";
import { ExerciseSettingsProvider } from "../constates/exerciseSettings";
import { OSMDProvider } from "../constates/osmd";
import { PlaybackProvider } from "../constates/playback";
import { ExerciseProps } from "../interfaces/exerciseProps";

const ExerciseScreen = () => {
	const { exercise, xml } = useExerciseContext();
	const [showSettings, setShowSettings] = useState(false);

	return (
		<>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" defaultHref="/" />
					</IonButtons>
					<IonTitle>{exercise?.name}</IonTitle>
					<IonButtons slot="end">
						{!!exercise && xml ? (
							<>
								{/* <IonButton
								onClick={async () => {
									
								}}
							>
								<IonIcon slot="icon-only" icon={informationCircleOutline} />
							</IonButton> */}
								<IonButton
									onClick={async () => {
										setShowSettings(!showSettings);
									}}
								>
									<IonIcon slot="icon-only" icon={settingsOutline} />
								</IonButton>
							</>
						) : (
							<IonButton>
								<IonSpinner />
							</IonButton>
						)}
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<Suspense
				fallback={
					<IonContent>
						<LoadingScreen />
					</IonContent>
				}
			>
				{!!exercise && xml ? (
					<ExerciseSettingsProvider exercise={exercise} xml={xml}>
						<OSMDProvider>
							<IonContent fullscreen>
								<SheetMusic xml={xml} />
							</IonContent>
							{xml && (
								<StyledIonFooter>
									<PlaybackProvider>
										<AudioPlayerControls />
									</PlaybackProvider>
								</StyledIonFooter>
							)}
							<IonModal isOpen={showSettings}>
								<ExerciseSettingsScreen
									onDismiss={() => {
										setShowSettings(false);
									}}
								/>
							</IonModal>
						</OSMDProvider>
					</ExerciseSettingsProvider>
				) : (
					<IonContent fullscreen>
						<LoadingScreen />
					</IonContent>
				)}
			</Suspense>
		</>
	);
};

const ExercisePage = () => {
	const params = useParams<ExerciseProps>();

	return (
		<IonPage>
			<ExerciseProvider id={params.id!} phonemePattern={params.phonemePattern}>
				<ExerciseScreen />
			</ExerciseProvider>
		</IonPage>
	);
};

export default ExercisePage;
